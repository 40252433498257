import {
  container,
  title,
  main,
  whiteColor,
  grayColor,
  mainRaised,
  blackColor,
  hexToRgb,
  customCardActionArea,
  textAlignCenter,
} from "assets/jss/material-kit-pro-react.js";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const presentationStyle = {
  ...footerStyle,
  customCardActionArea,
  textAlignCenter,
  main: {
    ...main,
  },
  bgWhite: {
    background: "#fff",
  },
  colorWhite: {
    color: "#fff !important",
  },
  mainRaised,
  parallax: {
    height: "100vh",
    overflow: "hidden",
    "@media (max-width: 768px)": {
      height: "50vh",
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  title: {
    ...title,
  },
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "4.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative",
    },
  },
  proBadge: {
    position: "relative",
    fontSize: "22px",
    textTransform: "uppercase",
    fontWeight: "700",
    right: "-10px",
    padding: "10px 18px",
    top: "-30px",
    background: whiteColor,
    borderRadius: "3px",
    color: grayColor[18],
    lineHeight: "22px",
    boxShadow: "0 5px 5px -2px rgba(" + hexToRgb(grayColor[25]) + ",.4)",
  },
  features: {
    textAlign: "center !important",
    paddingTop: "10px",
    paddingBottom: "50px",
    "& p": {
      fontSize: "16px",
      lineHeight: "1.6em",
    },
  },
  subTitle: {
    color: "#999",
    marginBottom: 50,
  },
  subHeader: {
    fontSize: 12,
    fontWeight: 500,
    marginBottom: "50px",
    textTransform: "Uppercase",
  },
  divDesc: {
    boxShadow: "1px 1px 8px 0px #d3d3d3",
    marginRight: 10,
    marginBottom: 40,
    height: "30rem",
    borderBottom: "3px solid #9e9e9e",
    padding: "40px",
  },
  card: {
    display: "flex",
    marginBottom: "20px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    textTransform: "none",
  },
  privateCover: {
    width: 1000,
  },
  cardDetails: {
    textAlign: "justify",
    textTransform: "none",
    color: "#545352",
    fontSize: "0.95rem",
    fontWeight: "300",
  },
  inquireButton: {
    color: "white",
    fontSize: "0.9rem",
  },
  weddingImg: {
    width: "100%",
    paddingRight: "0px",
    "@media (max-width: 768px)": {
      paddingLeft: "0",
      marginTop: "20px",
    },
  },
  bgimage: {
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
      background: "rgba(" + hexToRgb(blackColor) + ", 0.55)",
    },
  },
  eventCard: {
    position: "relative",
    zIndex: "10",
    textAlign: "center",
    height: "20rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& img": {
      height: "150px",
    },
  },
  paddingGrid: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    margin: "30px",
  },
  eventCardTitle: {
    color: "white",
    fontWeight: "400",
    textTransform: "uppercase",
    fontSize: "1.1rem",
  },
  eventButton: {
    zIndex: "2",
    width: "100%",
    margin: "0px !important",
    borderRadius: "0 !important",
    fontSize: "1rem !important",
    color: "#fff !important",
    fontWeight: "500 !important",
    "&:hover": {
      backgroundColor: "#b87333 !important",
      color: "#fff !important",
    },
  },
  description: {
    color: "#454243",
    textAlign: "center",
  },
  content: {
    marginBottom: 50,
  },
  bgImageCity: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "300px",
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
      background: "rgba(" + hexToRgb(blackColor) + ", 0.45)",
    },
  },
  cityGrid: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  cityTitleCard: {
    position: "relative",
    color: "white",
    zIndex: "2",
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  cityGridShadow: {
    margin: 20,
    marginBottom: 0,
    boxShadow: "1px 1px 6px 0px #0003",
    "@media (max-width: 768px)": {
      marginBottom: 20,
    },
  },
  hr: {
    marginTop: 50,
    marginBottom: 50,
    borderTop: "1px solid #d3d3d3",
  },
  eventMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  eventDescription: {
    textAlign: "center",
    color: "#454243",
  },
  titleWhite: {
    color: "white !important",
    "@media (max-width:768px)": {
      marginBottom: "0 !important",
    },
  },
  parallaxImg: {
    position: "relative",
    height: "45rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "5vh",
    backgroundAttachment: "fixed",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.25)",
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
    "@media (max-width:768px)": {
      paddingTop: "2vh",
      height: "38rem",
    },
  },
  eventParallaxImg: {
    position: "relative",
    backgroundImage: "url(/../../../Rooms/the_gaston_outside-10.jpg)",
    height: "48rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "5vh",
    backgroundAttachment: "fixed",
    marginTop: "50px",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)",
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      "@media (max-width:768px)": {
        display: "none",
      },
    },
    "@media (max-width:768px)": {
      paddingTop: "0",
      height: "auto",
      backgroundImage: "none",
    },
  },
  upcomingEventTitle: {
    color: "white !important",
    marginBottom: 50,
    marginTop: "-120px",
    paddingTop: "120px",
    "@media (max-width:786px)": {
      color: "#454243 !important",
      marginBottom: 10,
    },
  },
  upcomingEventCardHeader: {
    color: "#454243",
  },
  upcomingEventCard: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardActions: {
    justifyContent: "space-between",
    position: "relative",
  },
  emailLink: {
    color: "inherit",
    textDecoration: "underline",
  },
  upcomingEventGrid: {
    position: "relative",
    zIndex: "10",
  },
  weddingImages: {
    width: "100%",
    marginTop: 10,
    height: "100%",
  },
  weddingInquiryButton: {
    textAlign: "center",
    marginBottom: "40px",
  },
  weddingCard: {
    marginBottom: "40px",
    boxShadow: "1px 1px 3px 0px #0003",
    padding: "20px",
  },
  stayBlockMarginTop: {
    marginTop: "145px",
  },
  stayBlockMarginTopMobile: {
    marginTop: "122px",
  },
  fullWidth: {
    width: "100%",
  },
  weddingInquiryTitle: {
    marginTop: 0,
    paddingTop: 50,
  },
  bookStayMain: {
    minHeight: "85vh",
    paddingTop: 20,
  },
  titleDiv: {
    textAlign: "center",
    "& h5": {
      marginTop: 0,
      fontStyle: "italic",
    },
    "& h2": {
      marginBottom: 0,
    },
  },
  searchDiv: {
    padding: "30px",
    paddingTop: "10px",
    border: "1px solid #d3d3d3",
    "& h4": {
      textAlign: "left",
      marginBottom: 0,
      fontWeight: 400,
    },
  },
  roomListingDiv: {
    marginTop: 50,
    marginBottom: 50,
  },
  cover: {
    width: 1000,
    // height: 300
  },
  coverMobile: {
    height: 200,
  },
  dropdownCustom: {
    "& .MuiTextField-root": {
      width: 200,
    },
  },
  searchButtonDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },

  marginTopButtonMobile: {
    marginTop: "30px !important",
  },
  searchButton: {
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
  },
  reserveButtonDiv: {
    fontSize: "15px !important",
    fontWeight: "500 !important",
    borderTop: "1px solid #454243 !important",
    borderRadius: "0 !important",
    borderBottom: "1px solid #454243 !important",
    width: "100%",
  },
  removeButton: {
    fontSize: "15px !important",
    fontWeight: "500 !important",
    borderTop: "1px solid #f44336 !important",
    borderRadius: "0 !important",
    borderBottom: "1px solid #f44336 !important",
    width: "100%",
    color: "#f44336 !important",
  },
  reserveButtonDivMobile: {
    justifyContent: "center",
  },
  bookStayContent: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 15,
    paddingRight: 15,
  },
  reservationDetailsFloating: {
    height: 100,
    backgroundColor: "#b87333",
    position: "fixed",
    bottom: "0",
    zIndex: "99",
    left: "10px",
    right: "10px",
    paddingTop: 10,
    "& p": {
      color: "#fff",
      fontSize: "17px",
      fontWeight: "500",
    },
    "& button": {
      margin: 0,
      borderRadius: 0,
      fontSize: "0.95rem",
      color: "#454243",
      fontWeight: "bold",
    },
  },
  reservationContent: {
    display: "flex",
    justifyContent: "space-around",
  },
  bookingTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 20,
  },
  bookingDetailsMainDiv: {
    "& img": {
      width: "100%",
    },
    "& h2": {
      marginTop: 0,
    },
    "& h3": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& p": {
      marginBottom: 0,
    },
    "& h5": {
      marginBottom: 0,
    },
  },
  bookingSideDiv: {
    color: "#fff",
    backgroundColor: "#454243",
    textAlign: "center",
  },
  bookStayCards: {
    marginTop: 20,
  },
  bookStayCardContent: {
    minHeight: "16rem",
  },
  priceDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    "& p": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "1.1rem",
      "& svg": {
        marginRight: 5,
      },
    },
  },
  customCardMedia: {
    height: "12rem",
  },
  formSubmitButton: {
    textAlign: "center",
    marginTop: 40,
  },
  weddingInquiryContent: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "baseline",
    height: "100%",
  },
  upcomingEventButtons: {
    "&:hover": {
      backgroundColor: "#b87333 !important",
    },
  },
  makeReservationButton: {
    textAlign: "center",
    marginTop: "20px",
  },
  paymentChooseDiv: {
    border: "2px solid #454243",
    height: "100px",
    width: "100%",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
    "& span": {
width: "unset !important"
    },
    "& img": {
      height: "60px",
      width: "160px !important",
    },
  },
  iconCheck: {
    position: "absolute",
    right: "0",
    top: "0",
    color: "#b87333",
    height: "30px",
    width: "30px",
  },
  paymentMainDiv: {
    marginTop: 60,
  },
  paymentCheckDiv: {
    height: "1em",
    width: "1em",
    position: "absolute",
    top: "-10px",
    right: "0",
    backgroundColor: "white",
    borderRadius: "50%",
  },
  paymentSelected: {
    border: "4px solid #b87333 !important",
  },
  paymentDiv: {
    marginTop: "60px",
  },
  paymentSuccessDiv: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& h5": {
      fontWeight: 'bold',
      marginTop: 0
    }
  },
  successPayment: {
    height: "150px",
    marginTop: 50,
    marginBottom: 50
  },
  paymentContainer: {
    "@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5)": {
      minHeight: "70vh"
    }
  }
};

export default presentationStyle;
