const roomList = [
  {
    url: "/the_gaston_outside-001.jpg",
    title: "The Gaston Dallas",
    content: "The entire home will be reserved for you and your entire party.",
    price: "$850.00",
    mainPrice: 850,
    maxQty: 10
  },
  {
    url: "/Rooms/Adair/Adair-7.jpg",
    title: "Adair",
    content:
      'Named for "Will" Penn Adair Rogers, who started his life as a cowhand and went on to become a stage and motion-picture star.',
    price: "$150.00",
    mainPrice: 150,
    maxQty: 2
  },
  {
    url: "/Rooms/Dealey/Dealey-8.jpg",
    title: "Dealey ",
    content:
      "Named for Dealey Plaza, one of the most significant landmarks in Dallas history. The site marks the birthplace of Dallas and is most well-known for the assassination of President Kennedy in 1963. ",
    price: "$175.00",
    mainPrice: 175,
    maxQty: 2
  },
  {
    url: "/Rooms/Dentzel/Dentzel-6.jpg",
    title: "Dentzel ",
    content:
      "Named for Dentzel Carousel Company, which built the old-fashioned carousel that remains a popular and constant fixture at the State Fair of Texas. ",
    price: "$140.00",
    mainPrice: 140,
    maxQty: 2
  },
  {
    url: "/Rooms/Eastham/Eastham-8.jpg",
    title: "Eastham ",
    content:
      "Named for two of the most notorious outlaws in American history. Both Bonnie and Clyde moved to west Dallas at an early age, and eventually met at a friend's house in 1930.",
    price: "$175.00",
    mainPrice: 175,
    maxQty: 2
  },
  {
    url: "/Rooms/Varco/Varco-3.jpg",
    title: "Varco ",
    content:
      "Named for one of the biggest providers of equipment to the oil and gas industry. The discovery of the large oil reserves in the 1920s established Dallas as the financial center of the oil industry.",
    price: "$150.00",
    mainPrice: 150,
    maxQty: 2
  }
];

export default roomList;
