/* eslint-disable import/first */

import React, { Suspense } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() => import("components/Header/HeaderLinks.js"));
const Parallax = React.lazy(() => import("components/Parallax/Parallax.js"));
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const SectionInquiry = React.lazy(() => import("./Inquiry"));

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import Banner from "assets/img/weddings/the_gaston_house_weddings.jpg";

const useStyles = makeStyles(presentationStyle);

export default function WeddingInquiry() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (<Suspense fallback={<div>Loading...</div>}>
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
      />
      <Parallax image={Banner} className={classes.parallax} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <h2 className={classes.title + ' ' + classes.weddingInquiryTitle}>
            WEDDING INQUIRY
          </h2>
          <h4 className={classes.description}>
            Please note that all weddings hosted on site require full occupancy
            and two night minimum in order to preserve your experience.
          </h4>
          <SectionInquiry WeddingInquiry={true} />
        </div>

        <Footer
          content={
            <div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , Made By The Gaston{" "}
              </div>
            </div>
          }
        />
      </div>
    </div>
    </Suspense>
  );
}
