/* eslint-disable import/first */
import React, { Suspense, Component } from "react";
import { TextField, withStyles } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormHelperText } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { WeddingInquiry } from "../../services/formData";

const GridContainer = React.lazy(() =>
  import("components/Grid/GridContainer.js")
);
const GridItem = React.lazy(() => import("components/Grid/GridItem.js"));
const Button = React.lazy(() => import("components/CustomButtons/Button.js"));

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Inquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      date: new Date(),
      message: "",
      snackbarOpen: false,
      response: "",
      alert: "success"
    };
  }

  submitFormData = values => {
    const { first_name, last_name, email, date, message } = values;
    const formValues = {
      first_name,
      last_name,
      email,
      date: [date.getMonth()+1, date.getDate(), date.getFullYear()].join('/'),
      message,
      inquiry_type: "Wedding Inquiry"
    };

    WeddingInquiry(formValues)
      .then(result => {
        this.setState({
          snackbarOpen: true,
          response: "We will get back to you shortly"
        });
        setTimeout(() => window.location.reload(), 1500);
      })
      .catch(err => {
        this.setState({
          snackbarOpen: true,
          response: "Something went wrong, please try again letter ",
          alert: "error",
        });
        setTimeout(() => window.location.reload(), 1500);
      });
  };

  handleCloseSnackbar = () => {
    this.setState({
      snackbarOpen: false
    });
  };

  handleDateChange = date => {
    this.setState({
      date: date
    });
  };

  render() {
    const { classes } = this.props;
    const {
      snackbarOpen,
      first_name,
      last_name,
      email,
      date,
      message,
      response,
      alert
    } = this.state;
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <div className={classes.section + " " + classes.container}>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snackbarOpen}
            onClose={this.handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert severity={alert}>{response}</Alert>
          </Snackbar>
          <GridContainer justify="center">
            <GridItem xs={10} sm={10} md={8}>
              <Formik
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  first_name: Yup.string().required("First Name is required"),
                  last_name: Yup.string().required("Last Name is required"),
                  email: Yup.string()
                    .email("Email is invalid")
                    .required("Email is required"),
                  date: Yup.string().required("Date is required"),
                  message: Yup.string().required("Message is required")
                })}
                initialValues={{
                  first_name,
                  last_name,
                  email,
                  date,
                  message
                }}
                onSubmit={values => {
                  this.submitFormData(values);
                }}
              >
                {({ errors, values, touched, handleSubmit, handleChange }) => (
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <h5 className={classes.mb0}> First Name* </h5>
                        <TextField
                          fullWidth
                          name="first_name"
                          type="text"
                          margin="normal"
                          variant="outlined"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        {errors.first_name && touched.first_name && (
                          <FormHelperText error>
                            {errors.first_name}
                          </FormHelperText>
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <h5 className={classes.mb0}> Last Name* </h5>
                        <TextField
                          fullWidth
                          name="last_name"
                          type="text"
                          margin="normal"
                          variant="outlined"
                          value={values.last_name}
                          onChange={handleChange}
                        />
                        {errors.last_name && touched.last_name && (
                          <FormHelperText error>
                            {errors.last_name}
                          </FormHelperText>
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h5 className={classes.mb0}> Email* </h5>
                        <TextField
                          fullWidth
                          name="email"
                          type="email"
                          margin="normal"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <FormHelperText error>{errors.email}</FormHelperText>
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h5> Date </h5>
                        <DatePicker
                          selected={date}
                          onChange={this.handleDateChange}
                          value={values.date}
                          dateFormat="dd/MM/yyyy"
                        />
                        {errors.date && touched.date && (
                          <FormHelperText error>{errors.date}</FormHelperText>
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h5 className={classes.mb0}> Your Message* </h5>
                        <TextField
                          fullWidth
                          name="message"
                          type="text"
                          margin="normal"
                          variant="outlined"
                          multiline={true}
                          value={values.message}
                          onChange={handleChange}
                          rows="4"
                        />
                        {errors.message && touched.message && (
                          <FormHelperText error>
                            {errors.message}
                          </FormHelperText>
                        )}
                      </GridItem>

                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.mrAuto + " " + classes.mlAuto}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          color="rose"
                          style={{ color: "white" }}
                          onClick={handleSubmit}
                        >
                          {" "}
                          Inquire{" "}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                )}
              </Formik>
            </GridItem>
          </GridContainer>
        </div>
      </Suspense>
    );
  }
}
Inquiry.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Inquiry);
