/* eslint-disable import/first */

import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { isMobile, isBrowser } from "react-device-detect";
import { Link } from "react-router-dom";

const GridContainer = React.lazy(() =>
  import("components/Grid/GridContainer.js")
);
const GridItem = React.lazy(() => import("components/Grid/GridItem.js"));
const Button = React.lazy(() => import("components/CustomButtons/Button.js"));
const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() =>
  import("components/Header/HeaderLinks.js")
);
// const Parallax = React.lazy(() => import("components/Parallax/Parallax.js"));
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const RoomSlider = React.lazy(() =>
  import("components/RoomsSlider/RoomSlider")
);
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import RoomData from "./RoomData";

const useStyles = makeStyles(projectsStyle);

export default function RoomDetails() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const myId = window.location.hash.slice(1);
    const elem = document.getElementById(myId);
    if (elem) {
      elem.scrollIntoView();
    }
  });
  const classes = useStyles();
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Gaston | Dallas | Rooms</title>
          {/* <link rel="canonical" href="https://www.thegaston.com/rooms" /> */}
          <meta
            name="description"
            content="Welcome to The Gaston, a unique bed & breakfast located in Old East Dallas. The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
          />
          <meta
            name="keywords"
            content="The Gaston, Dallas, House, Old East Dallas"
          />
          <meta name="og:title" property="og:title" content="The Gaston" />
          <meta
            name="og:description"
            property="og:description"
            content="The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
          />
          <meta
            name="og:image"
            property="og:image"
            content="https://www.thegaston.com/Rooms/the_gaston_outside-9.jpg"
          />
        </Helmet>

        <Header
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="white"
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
        {/* <Parallax
          filter="dark"
          image={"/../../../Rooms/Eastham/Eastham-8.jpg"}
          className={classes.parallax}
        /> */}
        <video
          autoPlay
          loop
          muted
          playsInline
          className={isBrowser ? "videoHomepage" : "videoHomepage videoMobile"}
        >
          <source
            src={
              isMobile
                ? "https://d23l4wqnsivs21.cloudfront.net/30+sec+Front+page+The_Gaston_Dallas_bed_and_breakfast_+V2_opt_mobile.mp4"
                : "https://d23l4wqnsivs21.cloudfront.net/30+sec+Front+page+The_Gaston_Dallas_bed_and_breakfast_+V2_opt_desktop.mp4"
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1 style={{ display: "none" }}> The Gaston - Rooms </h1>

          <div className={classes.projects + " " + classes.projects4}>
            <div className={classes.container + " " + classes.gridRoom}>
              <GridContainer justify="center">
                <GridItem
                  xs={12}
                  sm={8}
                  md={8}
                  className={
                    classes.mlAuto +
                    " " +
                    classes.mrAuto +
                    " " +
                    classes.textCenter
                  }
                >
                  <h2 className={classes.title}>RESERVATIONS</h2>
                  <h5 className={classes.description}>
                    We offer guests the ability to book rooms individually and
                    also offer full-home rentals. Contact us for more details.
                  </h5>

                </GridItem>
              </GridContainer>
                  <hr />

              <GridContainer justify="center">
                <GridItem sm={12} xs={12} md={12} lg={12}>
                  {RoomData.map((room, index) => (
                    <>
                      <div key={room.title + index}>
                        <h3 className={classes.title + " " + classes.roomTitle}>
                          {room.title}
                        </h3>
                        <p className={classes.description}>
                          {room.description}
                        </p>
                        <div key={room.title + index} style={{ marginTop: 20 }}>
                          <RoomSlider data={room.images} />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Link to="/book-stay">
                            <Button color="rose" size="lg">
                              Book {room.title}
                            </Button>
                          </Link>
                        </div>
                      </div>
                      <hr />
                    </>
                  ))}
                </GridItem>
              </GridContainer>

              {!isMobile && (
                <div>
                  <h2 className={classes.title} style={{ marginTop: "0" }}>
                    Take a Virtual Tour
                  </h2>
                  <iframe
                    width="100%"
                    height="450"
                    src="https://www.google.com/maps/embed?pb=!4v1574794321175!6m8!1m7!1sCAoSLEFGMVFpcE9ZZ3kxMkJJVTJmSHh4ZkRzMEd6TW1ZY2J6Q0N2aV85Y2VPREww!2m2!1d32.7987657!2d-96.7706041!3f29.12!4f-18.959999999999994!5f0.7820865974627469"
                    frameBorder="0"
                    allowFullScreen
                    title="Gaston House Tour"
                    // allow="vr"
                  ></iframe>
                  <hr style={{ marginTop: 70 }} />
                </div>
                  
              )}

              <GridContainer
                justify="center"
                // className={classes.marginTopRefundPolicy}
              >
                <GridItem
                  sm={12}
                  xs={12}
                  md={10}
                  className={
                    classes.mlAuto +
                    " " +
                    classes.mrAuto +
                    " " +
                    classes.textCenter +
                    " " +
                    classes.book
                  }
                >
                  <h2 className={classes.title} style={{ marginTop: 0 }}>CANCELLATIONS & REFUNDS</h2>
                  <h5
                    className={classes.description}
                    style={{ color: "#454243" }}
                  >
                    We are a small independent operation and must put all our
                    efforts toward fulfilling the services of your reservation.
                    Thus, we require a 50% deposit on all reservations, and are
                    unable to offer refunds outside of 30 days of your stay. We
                    are aware that extenuating circumstances can arise, and we
                    will do our best to fulfill cancellation requests on a per
                    request basis, however we cannot guarantee a refund or
                    modification.
                  </h5>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <Footer />
        </div>
      </Suspense>
    </div>
  );
}
