/*eslint-disable*/
import React, { Suspense, Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import styles from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import { getBlogDetail, getAllBlog } from "services/blogs";

const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() =>
  import("components/Header/HeaderLinks.js")
);
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const Parallax = React.lazy(() => import("components/Parallax/Parallax.js"));
const GridItem = React.lazy(() => import("components/Grid/GridItem.js"));
const GridContainer = React.lazy(() =>
  import("components/Grid/GridContainer.js")
);
const Button = React.lazy(() => import("components/CustomButtons/Button.js"));

class BlogDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogDetail: "",
      id: props.location.state && props.location.state.blog,
      image: props.location.state && props.location.state.img,
      blogList: [],
      next: false
    };
  }

  async componentDidMount() {
    const { id, blogList } = this.state;
    const {
      history: { location: state }
    } = this.props;

    const res = await getAllBlog();
    if (res.error) {
      console.log(res);
    } else {
      let images = [];
      images = res.map(item => {
        return {
          id: item.id,
          image: item._embedded["wp:featuredmedia"][0].source_url
        };
      });
      this.setState(
        {
          blogList: res,
          images
        },
        () => {
          const { blogList } = this.state
          blogList.map(item => {           
            if (state.pathname.split("/")[2] === item.slug) {            
              this.setState({ id: item.id }, () => {
                const { id } = this.state
                getBlogDetail(id).then(res => {
                  if (res.error) {                  
                  } else {
                    this.setState({
                      blogDetail: res,
                      content: res.content.rendered,
                      title: res.title.rendered,
                      image: images.find((val) => val.id === id).image
                    });
                  }
                });
              });
            }
          });
        }
      );
    }
  }

  // previous = () => {};

  next = type => {
    const { blogList, id } = this.state;
    const blog = blogList.find(blog => blog.id === id);
    const index = blogList.indexOf(blog);
    const newIndex = type === "next" ? index + 1 : index - 1;
    const nextBlog = blogList[newIndex];
    if (blogList[newIndex]) {
      const newImg = nextBlog._embedded["wp:featuredmedia"][0].source_url;

      // window.location.reload()
      this.setState({ next: true, newImg, nextBlog }, () => {
        getBlogDetail(nextBlog.id).then(res => {
          if (res.error) {
            console.log(res);
          } else {
            this.setState({
              blogDetail: res,
              content: res.content.rendered,
              title: res.title.rendered,
              next: false,
              image: newImg,
              id: nextBlog.id
            });
          }
        });
      });
    }
  };

  rawMarkup(markup) {
    return { __html: markup };
  }

  render() {
    const { classes } = this.props;
    const { content, title, image, next, newImg, nextBlog } = this.state;
    if (next) {
      return (
        <Redirect
          to={{
            pathname: `/blog/${nextBlog.slug}`,
            state: { blog: nextBlog.id, img: newImg }
          }}
        />
      );
    }
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>The Gaston | Dallas | Blog</title>
            {/* <link rel="canonical" href="https://www.thegaston.com/events" /> */}
            <meta
              name="description"
              content="Welcome to The Gaston, a unique bed & breakfast located in Old East Dallas. The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
            />
            <meta
              name="keywords"
              content="The Gaston, Dallas, House, Old East Dallas"
            />
            <meta name="og:title" property="og:title" content="The Gaston" />
            <meta
              name="og:description"
              property="og:description"
              content="The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
            />
            <meta
              name="og:image"
              property="og:image"
              content="https://www.thegaston.com/Rooms/the_gaston_outside-9.jpg"
            />
          </Helmet>

          <Header
            brand="Material Kit PRO React"
            links={<HeaderLinks dropdownHoverColor="info" />}
            fixed
            color="white"
          />
          <Parallax image={image} className={classes.parallax} />
          <div className={classes.main}>
            <div className={classes.container}>
              <div className="blog">
                <h2
                  style={{
                    textAlign: "center",
                    color: "454243",
                    marginTop: "0px",
                    paddingTop: "50px"
                  }}
                >
                  <span style={{ fontWeight: "700" }}>{title}</span>
                </h2>
                <div style={{ paddingTop: 60, paddingBottom: 60 }}>
                  <div dangerouslySetInnerHTML={this.rawMarkup(content)} />{" "}
                </div>
              </div>
              <GridContainer justify="center" style={{ marginBottom: 30 }}>
                <GridItem md={2} sm={3} xs={4} style={{ textAlign: "center" }}>
                  <Button
                    fullWidth
                    color="rose"
                    onClick={() => this.next("previous")}
                  >
                    <ChevronLeftIcon />
                    Previous
                  </Button>
                </GridItem>

                <GridItem md={2} sm={3} xs={4} style={{ textAlign: "center" }}>
                  <Button
                    fullWidth
                    color="rose"
                    onClick={() => this.next("next")}
                  >
                    Next
                    <ChevronRightIcon />
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
            <Footer />
          </div>
        </Suspense>
      </div>
    );
  }
}
BlogDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BlogDetailsPage);
