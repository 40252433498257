/* eslint-disable import/first */

import React, { Suspense } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() => import("components/Header/HeaderLinks.js"));
const Parallax = React.lazy(() => import("components/Parallax/Parallax.js"));
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const GridContainer = React.lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = React.lazy(() => import("components/Grid/GridItem.js"));
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import Banner from "assets/img/the_gaston_house.jpg";

const useStyles = makeStyles(presentationStyle);

export default function TermsAndConditions() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (<Suspense fallback={<div>Loading...</div>}>
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
      />
      <Parallax image={Banner} className={classes.parallax} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <GridContainer justify="center">
          <GridItem
            xs={10}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h3 className={classes.title}>CANCELLATION POLICY</h3>
            <p style={{ color: "#454243", textAlign: "justify" }}>
              {" "}
              We are a small independent operation and must put all our efforts
              toward fulfilling the services of your reservation. Thus, we do
              not offer a cancellation policy. We are aware that extenuating
              circumstances can arise, and we will do our best to fulfill
              cancellation requests on a per request basis, however we cannot
              guarantee a refund or modification.
            </p>
            <h3 className={classes.title}>BOOKING POLICY</h3>
            <p style={{ color: "#454243", textAlign: "justify" }}>
              {" "}
              PROPERTY TERMS & CONDITIONS (Please read before booking) The
              Gaston is an adult playground. Therefore all guests must be 21
              years of age. No infants or children are allowed on property. We
              have a no pet policy. Exceptions will be made on a case by case
              basis and must be approved by ownership. We are a non-smoking
              property. Guests who violate the policy and smoke in their guest
              rooms are subject to a minimum $400.00 charge for returning the
              room to smoke-free status and agree to being charged by accepting
              this policy. Free parking and complimentary breakfast are
              available to all our overnight guests. All guest rooms are located
              on the second floor. There is no elevator and no bellhop. We
              reserve the right to charge for any damage to or removal of items
              from the premises and/or unusual cleaning. All photoshoots and
              filming are subject to advance approval by the owners. At least
              seven days advance written notice required prior to any personal
              or professional photo shoots. No closed door filming allowed.
              Additional fees apply. In other words, you may not simply reserve
              a room for a photo shoot or filming. This also applies to day of
              events such as weddings. A liaison designated by The Gaston will
              be assigned to monitor all photoshoots and filming. A separate
              contract is required. If you are caught filming on the premises
              without prior approval, all material will be forfeited and guests
              may be asked to leave the property. Items left behind and
              requested to be mailed will incur a minimum charge of $10 and may
              take up to 30 days to receive. We apologize for any inconvenience.
              To avoid this, please be sure to thoroughly check your room for
              items that are of value to you prior to your departure. Our
              signature clawfoot tubs are all antiques. We ask that you use our
              bath products to enjoy them. Using other products could
              potentially damage the enamel permanently. No bath bombs, glitter
              bombs, salts, etc. Using these products will result in a cleaning
              or replacement fee. Additional policies may apply to group
              reservations of three or more rooms.
            </p>
            <h3 className={classes.title}>PRIVACY POLICY</h3>
            <p
              style={{
                color: "#454243",
                textAlign: "justify",
                marginBottom: 50
              }}
            >
              {" "}
              Any information collected by us will be sent via a secure server.
              We will never share your information with a 3rd party.
            </p>
          </GridItem>
        </GridContainer>
        <Footer
          content={
            <div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , Made By The Gaston{" "}
              </div>
            </div>
          }
        />
      </div>
    </div>
    </Suspense>
  );
}
