/*eslint-disable*/
import React, { Suspense, Component } from "react";
import classNames from "classnames";
import { Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Helmet } from "react-helmet";
import {
  isBrowser,
  isTablet,
  isMobile,
  isMobileOnly,
} from "react-device-detect";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/CheckCircle";
import StripeCheckout from "react-stripe-checkout";
import { PayPalButton } from "react-paypal-button-v2";

const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() =>
  import("components/Header/HeaderLinks.js")
);
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const GridContainer = React.lazy(() => import("components/Grid/GridContainer"));
const GridItem = React.lazy(() => import("components/Grid/GridItem"));
const Img = React.lazy(() => import("components/Image/Image"));

import Paypal from "assets/img/payment/paypal.svg";
import Stripe from "assets/img/payment/stripe.png";
import Success from "assets/img/payment/success.svg";
import Gaston from "assets/img/favicon.png";
import styles from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomDetails:
        props.location.state && props.location.state.data.roomDetails,
      total: props.location.state && props.location.state.data.total,
      checkInDate:
        props.location.state && props.location.state.data.checkInDate,
      checkOutDate:
        props.location.state && props.location.state.data.checkOutDate,
      adults: props.location.state && props.location.state.data.adults,
      fromEvent: props.location.state && props.location.state.data.fromEvent,
      fromCustomEvent:
        props.location.state && props.location.state.data.fromCustomEvent,
      snackbarOpen: false,
      variant: "error",
      response: "",
      isStripe: false,
      isPayPal: false,
      isPaymentSuccess: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  handleCloseSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleSnackbarOpenSuccess = () => {
    this.setState({
      snackbarOpen: true,
      variant: "success",
      response: "Payment done successfully!",
    });
  };

  handleSnackbarOpenError = (message) => {
    this.setState({
      snackbarOpen: true,
      variant: "error",
      response: message,
    });
  };

  clickPaypal = () => {
    this.setState((prevState) => ({
      isPayPal: !prevState.isPayPal,
      isStripe: false,
    }));
  };

  clickStripe = () => {
    this.setState((prevState) => ({
      isStripe: !prevState.isStripe,
      isPayPal: false,
    }));
  };

  paymentSuccess = (details, data) => {
    console.log(details, data);
    this.setState({
      isPaymentSuccess: true,
    });
  };

  onToken = (token) => {
    console.log(token, "token");
    this.setState({
      isPaymentSuccess: true,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      roomDetails,
      total,
      checkInDate,
      checkOutDate,
      adults,
      snackbarOpen,
      variant,
      response,
      fromEvent,
      isStripe,
      isPayPal,
      isPaymentSuccess,
    } = this.state;
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>The Gaston | Dallas | Book Stay</title>
            <meta
              name="description"
              content="Welcome to The Gaston, a unique bed & breakfast located in Old East Dallas. The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
            />
            <meta
              name="keywords"
              content="The Gaston, Dallas, House, Old East Dallas"
            />
            <meta name="og:title" property="og:title" content="The Gaston" />
            <meta
              name="og:description"
              property="og:description"
              content="The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
            />
            <meta
              name="og:image"
              property="og:image"
              content="https://www.thegaston.com/Rooms/the_gaston_outside-9.jpg"
            />
          </Helmet>

          <Header
            links={<HeaderLinks dropdownHoverColor="info" />}
            fixed
            color="white"
            changeColorOnScroll={{
              height: 100,
              color: "white",
            }}
          />

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snackbarOpen}
            onClose={this.handleCloseSnackbar}
            autoHideDuration={2000}
          >
            <Alert severity={variant}>{response}</Alert>
          </Snackbar>

          <div
            className={classNames(
              classes.main,
              classes.mainRaised,
              isBrowser || isTablet
                ? classes.stayBlockMarginTop
                : classes.stayBlockMarginTopMobile
            )}
          >
            <div
              className={classes.container + " " + classes.paymentContainer}
              style={{ paddingBottom: 50, paddingTop: 50 }}
            >
              {isMobile && (
                <h2 className={classes.bookingTitle}> The Gaston Booking </h2>
              )}
              <div className={classes.bookingDetailsMainDiv}>
                <GridContainer justify="center">
                  <GridItem sm={12} xs={12} md={4} lg={4}>
                    <div className={classes.bookingSideDiv}>
                      <div>
                        <img src="/the_gaston_outside-001.jpg" />
                      </div>
                      <div
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingBottom: 30,
                          marginBottom: 20,
                        }}
                      >
                        {!fromEvent ? (
                          <h5> Your Reservation </h5>
                        ) : (
                          <h5> Event Booking Details </h5>
                        )}
                        <GridContainer justify="center">
                          <GridItem
                            sm={6}
                            md={6}
                            lg={6}
                            style={{ marginTop: 30 }}
                          >
                            <div>
                              <h3>{checkInDate}</h3>
                              <p> Check-in </p>
                            </div>
                          </GridItem>
                          <GridItem
                            sm={6}
                            md={6}
                            lg={6}
                            style={{ marginTop: 30 }}
                          >
                            <div>
                              <h3>{checkOutDate}</h3>
                              <p> Check-out </p>
                            </div>
                          </GridItem>
                          {!fromEvent && (
                            <>
                              <GridItem
                                sm={6}
                                md={6}
                                lg={6}
                                style={{ marginTop: 30 }}
                              >
                                <div>
                                  <h3> {roomDetails && roomDetails.length} </h3>
                                  <p> Room(s) </p>
                                </div>
                              </GridItem>
                              <GridItem
                                sm={6}
                                md={6}
                                lg={6}
                                style={{ marginTop: 30 }}
                              >
                                <div>
                                  <h3> {adults} </h3>
                                  <p> Guest(s) </p>
                                </div>
                              </GridItem>
                            </>
                          )}
                          <GridItem
                            sm={10}
                            md={10}
                            lg={10}
                            style={{ marginTop: 30 }}
                          >
                            <div>
                              <h3> $ {Math.round(total)}.00 </h3>
                              <p> Total </p>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  </GridItem>

                  <GridItem sm={12} xs={12} md={8} lg={8}>
                    {!isPaymentSuccess ? (
                      <>
                        <h2 className={classes.textAlignCenter}>
                          {" "}
                          Payment Information{" "}
                        </h2>
                        <GridContainer
                          justify="center"
                          className={classes.paymentMainDiv}
                        >
                          <GridItem lg={3} md={4} sm={4} xs={6}>
                            <div
                              className={
                                isPayPal
                                  ? classes.paymentChooseDiv +
                                    " " +
                                    classes.paymentSelected
                                  : classes.paymentChooseDiv
                              }
                              onClick={this.clickPaypal}
                            >
                              <Img src={Paypal} />
                              {isPayPal && (
                                <div className={classes.paymentCheckDiv}>
                                  <CheckIcon className={classes.iconCheck} />
                                </div>
                              )}
                            </div>
                          </GridItem>
                          <GridItem lg={3} md={4} sm={4} xs={6}>
                            <div
                              className={
                                isStripe
                                  ? classes.paymentChooseDiv +
                                    " " +
                                    classes.paymentSelected
                                  : classes.paymentChooseDiv
                              }
                              onClick={this.clickStripe}
                            >
                              <Img src={Stripe} />
                              {isStripe && (
                                <div className={classes.paymentCheckDiv}>
                                  <CheckIcon className={classes.iconCheck} />
                                </div>
                              )}
                            </div>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem md={6}>
                            <div className={classes.paymentDiv}>
                              {isPayPal && (
                                <PayPalButton
                                  amount={total}
                                  options={{
                                    clientId: "sb",
                                    currency: "EUR",
                                  }}
                                  onSuccess={(details, data) =>
                                    this.paymentSuccess(details, data)
                                  }
                                  onButtonReady={() =>
                                    this.setState({ showLoading: false })
                                  }
                                  catchError={(err) => console.log(err, "err")}
                                />
                              )}
                              {isStripe && (
                                <StripeCheckout
                                  amount={total}
                                  description="The Gaston House Booking"
                                  image={Gaston}
                                  locale="auto"
                                  name="The Gaston"
                                  stripeKey="pk_test_6pRNASCoBOKtIshFeQd4XMUh"
                                  token={this.onToken}
                                  zipCode
                                />
                              )}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </>
                    ) : (
                      <div className={classes.paymentSuccessDiv}>
                        <Img
                          src={Success}
                          alt="Payment Successful"
                          className={classes.successPayment}
                        />
                        <h2>Thank You !</h2>
                        <h5>Your Payment is Successfully Done.</h5>
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            {<Footer />}
          </div>
        </Suspense>
      </div>
    );
  }
}
Payment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Payment);
