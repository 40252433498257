import axios from "axios";
const blogApiBase = process.env.REACT_APP_BLOGS_BASE_URI;
const blogDetailApiBase = process.env.REACT_APP_BLOG_DETAIL_BASE_URI;

export async function getAllBlog() {
  return axios
    .get(`${blogApiBase}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export async function getBlogDetail(id) {
  return axios
    .get(`${blogDetailApiBase}${id}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}
