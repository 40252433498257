import axios from "axios";
import { GetBaseApiEndPoint } from "../appConfig";
const apibase = GetBaseApiEndPoint();

let reqConfig = {
  headers: {
    "Content-Type": "application/json"
  }
};

export async function EventFormData(url, data) {
  return axios
    .post(`${apibase}${url}`, data, reqConfig)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

// export async function EventFormData(url, data) {
// 	return new Promise((resolve, reject) => {
// 		fetch(`${apibase}${url}`, {
// 			method: 'POST',
// 			body: JSON.stringify(data)
// 		})
// 		.then((response) => response.json())
// 		.then((res) => {
// 			resolve(res);
// 		})
// 		.catch((error) => {
// 			reject(error);
// 		});
// 	});
// }

export function WeddingInquiry(formData) {
  return axios
    .post(apibase + "/wedding-inquiries", formData, reqConfig)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
}

export function PhotoShootInquiry(formData) {
  return axios
    .post(apibase + "/wedding-inquiries", formData, reqConfig)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
}
