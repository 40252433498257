import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import SectionInquiry from "../Weddings/Inquiry";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function PhotoShootInquiry() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
      />
      <Parallax
        // image={("/../../../the_gaston_house.jpg")}
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>

        <div className={classes.container}>
          <h2 className={classes.title} style={{ paddingTop: 50 }}>PHOTOSHOOT INQUIRY</h2>
          <h4 className={classes.description}>
            Please note that all Photoshoot hosted on site require full occupancy and two night minimum in order to preserve your experience.
                    </h4>
          <SectionInquiry PhotoShootInquiry={true} />
        </div>


        <Footer
          content={
            <div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , Made By The Gaston{" "}
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
