import React from "react";
import Carousel from "react-slick";
import { makeStyles } from "@material-ui/core/styles";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

const useStyles = makeStyles(headersStyle);

export default function SectionHeaders({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    swipeToSlide: true
  };
  return (
    <div className="cd-section" {...rest}>
      <div>
        <Carousel {...settings}>
          {/* <div>
            <div className={classes.pageHeader}>
              <video
                autoPlay
                loop
                muted
                playsInline
                className={isBrowser ? "videoDesktop" : "videoMobile"}
              >
                <source
                  src={"/../../../The_Gaston_Dallas_video.mp4"}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div> */}

          <div>
            <div
              className={classes.pageHeader}
              style={{
                backgroundImage: "url('/../../../the_gaston_outside-001.jpg')"
              }}
            ></div>
          </div>

          <div>
            <div
              className={classes.pageHeader}
              style={{
                backgroundImage: "url('/../../../Rooms/Eastham/Eastham-8.jpg')"
              }}
            ></div>
          </div>

          <div>
            <div
              className={classes.pageHeader}
              style={{
                backgroundImage: "url('/../../../Tours/GastonRefectory.jpg')"
              }}
            ></div>
          </div>

          <div>
            <div
              className={classes.pageHeader}
              style={{
                backgroundImage: "url('/../../../Tours/GastonDiningRoom-2.jpg')"
              }}
            ></div>
          </div>
          <div>
            <div
              className={classes.pageHeader}
              style={{
                backgroundImage: "url('/../../../the_gaston_outside-2.jpg')"
              }}
            ></div>
          </div>
          <div>
            <div
              className={classes.pageHeader}
              style={{
                backgroundImage:
                  "url('/../../../Rooms/the_gaston_outside-9.jpg')"
              }}
            ></div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
