import axios from "axios";
const propertyApiBase = process.env.REACT_APP_LODGIFY_PROPERTY_URI;
const availabilityApiBase = process.env.REACT_APP_LODGIFY_AVAILABILITY_URI;
const bookingApiBase = process.env.REACT_APP_LODGIFY_AVAILABILITY_URI;

let reqConfig = {
  headers: {
    "X-ApiKey":
      "v3M+ukwuf210cbZAe5wgTXjeKAVHFlfzQGeSEwQScsTm7nTwBhwqXuTqe7J+ajNK"
  }
};

export async function getProperties() {
  return axios
    .get(`${propertyApiBase}`, reqConfig)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export async function getAvailability(roomData) {
  return axios
    .post(`${availabilityApiBase}`, roomData, reqConfig)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export async function booking(roomData) {
  return axios
    .post(`${bookingApiBase}`, roomData, reqConfig)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}
