import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import { BrowserView } from "react-device-detect";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import RoomIcon from "@material-ui/icons/Room";

import styles from "../../assets/jss/material-kit-pro-react/components/headerStyle";
import logo from "../../assets/img/the_gaston_logo.svg";
import "./Header.scss";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;

    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, links, fixed, absolute } = props;

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });

  return (
    <AppBar
      className={appBarClasses}
      style={{ paddingTop: 0, flexDirection: "column" }}
    >
      <BrowserView style={{ width: "100%" }}>
        <div className={classes.topHeaderBrowser}>
          <div className={classes.topHeaderContent}>
            <h6
              className={classes.marginRight}
              style={{
                textTransform: "lowercase",
                display: "flex",
                alignItems: "center"
              }}
            >
              <EmailIcon style={{ fontSize: 14, marginRight: 10 }} />
              <a href="mailto: info@thegaston.com" style={{ color: "inherit" }}>
                {" "}
                info@thegaston.com{" "}
              </a>
            </h6>
            <h6
              className={classes.marginRight}
              style={{ display: "flex", alignItems: "center" }}
            >
              <PhoneIcon style={{ fontSize: 14, marginRight: 10 }} />
              <a href="tel:+1 (469) 631-1166" style={{ color: "inherit" }}>
                {" "}
                +1 (469) 631-1166
              </a>
            </h6>
            <h6
              className={classes.marginRight}
              style={{ display: "flex", alignItems: "center" }}
            >
              <a
                href="https://goo.gl/maps/wW6vCkT5oAxdsxJfA"
                target="_Blank"
                rel="noopener noreferrer"
                style={{ color: "inherit" }}
              >
                <RoomIcon style={{ fontSize: 14, marginRight: 10 }} />
                4802 Gaston Ave, Dallas, Texas 75246
              </a>
            </h6>
            <h6 style={{ display: "flex", alignItems: "center" }}>
              <a
                href="https://www.instagram.com/thegastondallas/"
                target="_Blank"
                rel="noopener noreferrer"
                style={{
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                  marginRight: 30
                }}
              >
                <i className="fab fa-instagram" />
              </a>
              <a
                href="https://www.facebook.com/TheGastonDallasTX"
                target="_Blank"
                rel="noopener noreferrer"
                style={{
                  color: "inherit",
                  display: "flex",
                  alignItems: "center"
                }}
                className={classes.topHeaderLocation}
              >
                <i className="fab fa-facebook" />
              </a>
            </h6>
          </div>
        </div>
      </BrowserView>
      <Toolbar className={classes.container}>
        <Button className={classes.title}>
          <Link to="/">
            <img src={logo} className="imgWidth" alt="The Gaston logo" />
          </Link>
        </Button>
        <Hidden smDown implementation="css" className={classes.hidden}>
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden mdUp>
          <div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              style={{ cursor: "pointer" }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      marginRight: 10,
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "2"
                    }}
                  >
                    {" "}
                    Menu{" "}
                  </p>
                  <Menu />
                </div>
              </div>
            </IconButton>
            <div style={{ position: "relative", top: "-12px" }}>
              <Button href="/book-stay" className={classes.stayButton}>
                <img
                  src="/../../../house.svg"
                  className={classes.stayButtonIcon}
                  alt="The Gaston - Book Stay"
                />{" "}
                Stay
              </Button>
            </div>
          </div>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
    "blackColor"
  ]),
  links: PropTypes.node,
  // brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
      "blackColor"
    ]).isRequired
  })
};
