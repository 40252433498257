import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import {
  container,
  mrAuto,
  title,
  blackColor,
  whiteColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

const headersSection = theme => ({
  ...headerLinksStyle(theme),
  sectionBlank: {
    height: "70px",
    display: "block"
  },
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor
    }
  },
  conatinerHeader2: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor
    },
    paddingTop: "25vh"
  },
  title,
  pageHeader: {
    position: "relative",
    height: "100vh",
    maxHeight: "1600px",
    backgroundPosition: "top",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "@media (max-width:768px)": {
      height: "60vh"
    },
    "&:before": {
      // background: "rgba(" + hexToRgb(blackColor) + ", 0.55)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  BookStayHeader: {
    position: "relative",
    height: "100vh",
    maxHeight: "1600px",
    backgroundPosition: "top",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.15)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  iframeContainer: {
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    }
  },
  mrAuto,
  textCenter: {
    textAlign: "center"
  },
  card: {
    marginTop: "60px"
  },
  formControl: {
    margin: "0",
    padding: "8px 0 0 0"
  },
  textRight: {
    textAlign: "right"
  },
  button: {
    margin: "0 !important"
  },
  bannerText: {
    position: "absolute",
    top: "38%",
    zIndex: "99",
    color: "white",
    left: "15%",
    backgroundColor: "#454243d1",
    padding: "10px",
    "& p": {
      fontSize: "1.5rem",
      "@media (max-width:768px)": {
        fontSize: "0.7rem",
        marginTop: "0px !important",
        marginBottom: "5px !important"
      },
      "@media only screen and (min-device-width: 480px) and (max-device-width: 1100px) and (orientation: landscape)": {
        fontSize: "0.8rem"
      }
    },
    "@media (max-width:768px)": {
      top: "40%",
      left: "15%",
      right: "15%",
      padding: "5px"
    },
    "@media only screen and (min-device-width: 480px) and (max-device-width: 1100px) and (orientation: landscape)": {
      top: "40%",
      left: "15%",
      right: "15%"
    }
  },
  bannerTitle: {
    textTransform: "uppercase",
    "@media (max-width:768px)": {
      fontSize: "1rem",
      marginTop: "5px"
    },
    "@media only screen and (min-device-width: 480px) and (max-device-width: 1100px) and (orientation: landscape)": {
      fontSize: "1.2rem"
    }
  },
  bookStayHeader: {
    height: "100vh"
  },
  headerBookingSection: {
    position: "absolute",
    zIndex: "9",
    top: "0",
    width: "100%",
    height: "100%"
  },
  mainDivBookingSection: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h4": {
      color: "#fff",
      fontWeight: "500"
    }
  },
  searchButtonDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    width: "100%"
  },
  customGridContainer: {
    backgroundColor: "#4542437a",
    paddingBottom: 30,
    paddingTop: 10,
    "@media (max-width: 768px)": {
      marginLeft: "45px !important",
      marginRight: "45px !important"
    }
  },
  marginTopButtonMobile: {
    marginTop: "30px !important"
  },
  searchButton: {
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    width: "100%",
    "&:hover": {
      backgroundColor: "#b87333 !important",
      color: "#fff !important"
    }
  }
});

export default headersSection;
