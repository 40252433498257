/* eslint-disable import/first */

import React, { Suspense } from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import RoomIcon from "@material-ui/icons/Room";

const GridContainer  = React.lazy(() => import("components/Grid/GridContainer.js"));
const GridItem  = React.lazy(() => import("components/Grid/GridItem.js"));
const Button  = React.lazy(() => import("components/CustomButtons/Button.js"));
import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();

  const footerAlign = classNames({
    [classes.gridFooter]: true,
    [classes.textCenter]: isMobile
  });

  const subFooterAlign = classNames({
    [classes.gridFooter]: true,
    [classes.textCenter]: isMobile
  });

  const nceptioFooter = classNames({
    [classes.footerBottom]: !isMobile,
    [classes.textCenter]: isMobile
  });

  return (
    <Suspense fallback={<div>Loading...</div>}>

    <div className={classes.mainFooter}>
      <div className={classes.container}>
        <GridContainer
          justify="space-around"
          className={classes.paddingContainer + " " + classes.footerFontStyle}
        >
          <GridItem sm={6} xs={12} md={"auto"} className={footerAlign}>
            <h6>Location</h6>
            <a
              href="https://goo.gl/maps/wW6vCkT5oAxdsxJfA"
              target="_Blank"
              rel="noopener noreferrer"
            >
              <p>
                <RoomIcon style={{ fontSize: 14, marginRight: 10 }} />
                4802 Gaston Ave
              </p>
              <p>Dallas, Texas 75246</p>
            </a>
          </GridItem>
          <GridItem sm={6} xs={12} md={"auto"} className={footerAlign}>
            <h6>CONTACT</h6>
            <p>
              <EmailIcon style={{ fontSize: 14, marginRight: 10 }} />
              <a href="mailto: info@thegaston.com"> info@thegaston.com </a>
            </p>
            <p>
              <PhoneIcon style={{ fontSize: 14, marginRight: 10 }} />
              <a href="tel:+1 (469) 631-1166"> +1 (469) 631-1166 </a>
            </p>
          </GridItem>
          <GridItem sm={6} xs={12} md={"auto"} className={footerAlign}>
            <h6>LEGAL</h6>

            <Link to="/policies">
              {" "}
              <p style={{ color: "white" }}> Policies </p>
            </Link>
            <Link to="/terms-conditions">
              {" "}
              <p style={{ color: "white" }}> Terms and Conditions </p>
            </Link>
            <a href="/sitemap.xml">
              <p style={{ color: "white" }}> Sitemap </p>
            </a>
          </GridItem>
          <GridItem sm={6} xs={12} md={"auto"} className={footerAlign}>
            <h6>Follow</h6>
            <Button
              justIcon
              simple
              href="https://www.instagram.com/thegastondallas/"
              color="white"
              target="_blank"
              style={{
                margin: "0",
                padding: "0",
                height: "15px",
                width: "15px"
              }}
            >
              <i className="fab fa-instagram" />
            </Button>
            <Button
              justIcon
              simple
              href="https://www.facebook.com/TheGastonDallasTX"
              color="white"
              target="_blank"
              style={{
                margin: "0",
                padding: "0",
                height: "15px",
                width: "15px"
              }}
            >
              <i className="fab fa-facebook" />
            </Button>
          </GridItem>
        </GridContainer>
      </div>

      <hr className={classes.footerDivider} />
      <div className={classes.container}>
        <GridContainer
          justify="space-around"
          className={classes.footerFontStyle}
        >
          <GridItem sm={6} xs={12} md={"auto"} className={subFooterAlign}>
            <p>Copyright 2019, All rights reserved</p>
          </GridItem>
          <GridItem
            sm={6}
            xs={12}
            md={"auto"}
            className={subFooterAlign}
            style={{ paddingBottom: "10px" }}
          >
            <div className={nceptioFooter}>
              <p> managed with ❤ by </p>
              <a
                href="https://nceptio.com/"
                target="_Blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/nceptio_logo2.png"
                  className={classes.footerLogo}
                  alt="Nceptio"
                />
              </a>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
    </Suspense>
  );
}
