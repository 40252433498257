import React, { useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import Carousel from "react-slick";
import { isMobile } from "react-device-detect";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import { getAvailability } from "services/bookStay";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
const Header = React.lazy(() => import("components/Header/Header"));
const HeaderLinks = React.lazy(() => import("components/Header/HeaderLinks"));
const GridContainer = React.lazy(() => import("components/Grid/GridContainer"));
const GridItem = React.lazy(() => import("components/Grid/GridItem"));
const Button = React.lazy(() => import("components/CustomButtons/Button"));
const Dialog = React.lazy(() => import("./EventDialog"));

const useStyles = makeStyles(headersStyle);

export default function SectionHeaders(props) {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    swipeToSlide: true
  };
  const fromEvent = props.location.state && props.location.state.fromEvent;
  const [adults, setAdults] = useState(2);
  const entireProperty = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(
    new Date(moment(new Date()).add(1, "days"))
  );
  const [open, setOpen] = useState(false);

  function handleCheckInDateChange(date) {
    setCheckInDate(new Date(date));
    setCheckOutDate(new Date(moment(date).add(1, "days")));
  }

  function handleCheckOutDateChange(date) {
    setCheckOutDate(new Date(date));
  }

  function handleChange(e) {
    setAdults(e.target.value);
  }

  function handleSubmit() {
    const data = {
      checkInDate: [
        checkInDate.getMonth() + 1,
        checkInDate.getDate(),
        checkInDate.getFullYear()
      ].join("/"),
      checkOutDate: [
        checkOutDate.getMonth() + 1,
        checkOutDate.getDate(),
        checkOutDate.getFullYear()
      ].join("/")
    };

    const bookingData = {
      checkInDate,
      checkOutDate,
      adults
    };
    const uri = encodeURIComponent(
      "check_in_from" + data.checkInDate + "to_check_out" + data.checkOutDate
    );
    props.history.push(`/book-stay/${uri}`, { bookingData });
  }

  function searchRooms() {
    const checkIn = [
      checkInDate.getFullYear(),
      checkInDate.getMonth() + 1,
      checkInDate.getDate()
    ].join("/");
    const checkOut = [
      checkOutDate.getFullYear(),
      checkOutDate.getMonth() + 1,
      checkOutDate.getDate()
    ].join("/");

    const data = {
      sort: "default",
      location_id: "0,229,0,4643,0,6311110",
      start: checkIn,
      end: checkOut,
      people: adults
    };
    getAvailability(data).then(res => {
      if (res.error) {
        console.log(res.error);
      } else {
        const roomList = res.data.items;
        if (roomList.length < 6) {
          setOpen(true);
        } else {
          const uri = encodeURIComponent(
            "check_in_from" + checkIn + "to_check_out" + checkOut
          );
          props.history.push(`/book-stay/${uri}/${uri}`, {
            checkInDate: checkIn,
            checkOutDate: checkOut,
            fromEvent: true
          });
        }
      }
    });
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Gaston | Dallas | Book Stay</title>
          <meta
            name="description"
            content="Welcome to The Gaston, a unique bed & breakfast located in Old East Dallas. The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
          />
          <meta
            name="keywords"
            content="The Gaston, Dallas, House, Old East Dallas"
          />
          <meta name="og:title" property="og:title" content="The Gaston" />
          <meta
            name="og:description"
            property="og:description"
            content="The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
          />
          <meta
            name="og:image"
            property="og:image"
            content="https://www.thegaston.com/Rooms/the_gaston_outside-9.jpg"
          />
        </Helmet>
        <Header
          absolute
          color="white"
          links={<HeaderLinks dropdownHoverColor="info" />}
        />
        <Dialog open={open} handleClose={handleClose} />
        <div className={classes.bookStayHeader}>
          <Carousel {...settings}>
            <div>
              <div
                className={classes.BookStayHeader}
                style={{
                  backgroundImage:
                    "url('/../../../Rooms/the_gaston_outside-9.jpg')"
                }}
              ></div>
            </div>
            <div>
              <div
                className={classes.BookStayHeader}
                style={{
                  backgroundImage: "url('/../../../Rooms/Adair/Adair-7.jpg')"
                }}
              ></div>
            </div>
            <div>
              <div
                className={classes.BookStayHeader}
                style={{
                  backgroundImage: "url('/../../../Rooms/Dealey/Dealey-7.jpg')"
                }}
              ></div>
            </div>

            <div>
              <div
                className={classes.BookStayHeader}
                style={{
                  backgroundImage:
                    "url('/../../../Rooms/Dentzel/Dentzel-6.jpg')"
                }}
              ></div>
            </div>
            <div>
              <div
                className={classes.BookStayHeader}
                style={{
                  backgroundImage:
                    "url('/../../../Rooms/Dentzel/Dentzel-7.jpg')"
                }}
              ></div>
            </div>
            <div>
              <div
                className={classes.BookStayHeader}
                style={{
                  backgroundImage:
                    "url('/../../../Rooms/Eastham/Eastham-10.jpg')"
                }}
              ></div>
            </div>
            <div>
              <div
                className={classes.BookStayHeader}
                style={{
                  backgroundImage: "url('/../../../Rooms/Varco/Varco-3.jpg')"
                }}
              ></div>
            </div>
          </Carousel>
          <div className={classes.headerBookingSection}>
            <div className={classes.mainDivBookingSection}>
              <div className="bookingSection">
                <GridContainer
                  justify="center"
                  className={classes.customGridContainer}
                >
                  <GridItem xs={10} sm={10} md={3}>
                    <h4> Check-in </h4>
                    <div className="customDatePicker">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          format={"MM/DD/YYYY"}
                          variant={"inline"}
                          value={checkInDate}
                          onChange={handleCheckInDateChange}
                          autoOk
                          disablePast
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </GridItem>
                  <GridItem xs={10} sm={10} md={3}>
                    <h4> Check-out </h4>
                    <div className="customDatePicker">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          format={"MM/DD/YYYY"}
                          variant={"inline"}
                          value={checkOutDate}
                          onChange={handleCheckOutDateChange}
                          autoOk
                          disablePast
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </GridItem>
                  {!fromEvent && (
                    <GridItem xs={10} sm={6} md={3}>
                      <h4> Adults </h4>
                      <TextField
                        id="standard-select-currency"
                        select
                        fullWidth
                        variant="outlined"
                        value={adults}
                        size="small"
                        onChange={handleChange}
                      >
                        {entireProperty.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </GridItem>
                  )}

                  <GridItem
                    xs={10}
                    sm={10}
                    md={3}
                    className={classes.searchButtonDiv}
                  >
                    {!fromEvent ? (
                      <Button
                        color="white"
                        onClick={handleSubmit}
                        className={
                          isMobile
                            ? classes.marginTopButtonMobile +
                              " " +
                              classes.searchButton
                            : classes.searchButton
                        }
                      >
                        <SearchIcon /> SEARCH{" "}
                      </Button>
                    ) : (
                      <Button
                        color="white"
                        onClick={searchRooms}
                        className={
                          isMobile
                            ? classes.marginTopButtonMobile +
                              " " +
                              classes.searchButton
                            : classes.searchButton
                        }
                      >
                        <SearchIcon /> SEARCH{" "}
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </div>
  );
}
