/*eslint-disable*/

import React, { Suspense, Component } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { TextField, MenuItem, FormHelperText } from "@material-ui/core";
import { isBrowser, isTablet, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Switch from "react-switch";
import { Formik } from "formik";
import * as Yup from "yup";

const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() =>
  import("components/Header/HeaderLinks.js")
);
const Button = React.lazy(() => import("components/CustomButtons/Button.js"));
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const GridContainer = React.lazy(() => import("components/Grid/GridContainer"));
const GridItem = React.lazy(() => import("components/Grid/GridItem"));

import CardMedia from "components/Card/CardMedia";
import styles from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import moment from "moment";

class BookStay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkInDate: new Date(),
      checkOutDate: new Date(),
      eventCategory: "",
      categories: ["Wedding", "Photo shoot", "House Party", "Other"],
      name: "",
      phoneNumber: "",
      email: "",
      noOfPeople: "",
      isConfirm: false,
      foodChecked: false,
      decorations: false,
      total: 1200,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  handleCheckInDateChange = (date) => {
    this.setState({
      checkInDate: new Date(date),
      checkOutDate: new Date(moment(date).add(1, "days")),
    });
  };

  handleCheckOutDateChange = (date) => {
    this.setState({
      checkOutDate: new Date(date),
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  confirmBooking = () => {
    this.setState({
      isConfirm: true,
    });
  };

  handleAddonsOneChange = () => {
    this.setState(
      (prevState) => ({
        foodChecked: !prevState.foodChecked,
      }),
      () => {
        this.state.foodChecked &&
          this.setState({
            total: this.state.total + this.state.noOfPeople * 40,
          });
        !this.state.foodChecked &&
          this.setState({
            total: this.state.total - this.state.noOfPeople * 40,
          });
      }
    );
  };

  handleAddonsTwoChange = () => {
    this.setState(
      (prevState) => ({
        decorations: !prevState.decorations,
      }),
      () => {
        this.state.decorations &&
          this.setState({
            total: this.state.total + 500,
          });
        !this.state.decorations &&
          this.setState({
            total: this.state.total - 500,
          });
      }
    );
  };

  goToPayment = () => {
    const { checkInDate, checkOutDate, total } = this.state;
    const checkIn = [
      checkInDate.getMonth() + 1,
      checkInDate.getDate(),
      checkInDate.getFullYear(),
    ].join("/");
    const checkOut = [
      checkOutDate.getMonth() + 1,
      checkOutDate.getDate(),
      checkOutDate.getFullYear(),
    ].join("/");
    const uriOne = encodeURIComponent("check_in_from" + checkIn);
    const uriTwo = encodeURIComponent("to_check_out" + checkOut);
    this.props.history.push(`/book-stay/${uriOne}/${uriTwo}`, {
      total: total,
      checkInDate: checkIn,
      checkOutDate: checkOut,
      fromEvent: true,
      fromCustomEvent: true,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      checkInDate,
      checkOutDate,
      eventCategory,
      categories,
      name,
      phoneNumber,
      email,
      noOfPeople,
      isConfirm,
      foodChecked,
      decorations,
      total,
    } = this.state;
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Header
            links={<HeaderLinks dropdownHoverColor="info" />}
            fixed
            color="white"
            changeColorOnScroll={{
              height: 100,
              color: "white",
            }}
          />
          <div
            className={classNames(
              classes.main,
              classes.mainRaised,
              isBrowser || isTablet
                ? classes.stayBlockMarginTop
                : classes.stayBlockMarginTopMobile
            )}
          >
            <div className={classes.container} style={{ paddingBottom: 50 }}>
              <h2 className={classes.title}>Book Your Events</h2>
              <h4 className={classes.description}>
                You can organize your own events here at Gaston!
              </h4>
              <Formik
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Name is Required"),
                  email: Yup.string()
                    .required("Email is Required")
                    .email("Email is Invalid"),
                  phoneNumber: Yup.string().required(
                    "Phone Number is Required"
                  ),
                  eventCategory: Yup.string().required(
                    "Event Category is Required"
                  ),
                  noOfPeople: Yup.string().required(
                    "Number of people is Required"
                  ),
                })}
                initialValues={{
                  name,
                  email,
                  phoneNumber,
                  eventCategory,
                  noOfPeople,
                }}
                onSubmit={(values) => {
                  this.confirmBooking();
                }}
              >
                {({ errors, values, touched, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={9} md={9}>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <h5 className={classes.mb0}> Full Name* </h5>
                            <TextField
                              fullWidth
                              name="name"
                              variant="outlined"
                              value={values.name}
                              size="small"
                              onChange={this.handleChange}
                            />
                            {errors.name && touched.name && (
                              <FormHelperText error>
                                {" "}
                                {errors.name}{" "}
                              </FormHelperText>
                            )}
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <h5 className={classes.mb0}> Email* </h5>
                            <TextField
                              fullWidth
                              name="email"
                              variant="outlined"
                              value={values.email}
                              size="small"
                              onChange={this.handleChange}
                            />
                            {errors.email && touched.email && (
                              <FormHelperText error>
                                {" "}
                                {errors.email}{" "}
                              </FormHelperText>
                            )}
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <h5 className={classes.mb0}> Phone Number* </h5>
                            <TextField
                              fullWidth
                              name="phoneNumber"
                              variant="outlined"
                              value={values.phoneNumber}
                              size="small"
                              onChange={this.handleChange}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <FormHelperText error>
                                {" "}
                                {errors.phoneNumber}{" "}
                              </FormHelperText>
                            )}
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <h5 className={classes.mb0}> Event Category* </h5>
                            <TextField
                              select
                              fullWidth
                              name="eventCategory"
                              variant="outlined"
                              value={values.eventCategory}
                              size="small"
                              onChange={this.handleChange}
                            >
                              {categories.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                            {errors.eventCategory && touched.eventCategory && (
                              <FormHelperText error>
                                {" "}
                                {errors.eventCategory}{" "}
                              </FormHelperText>
                            )}
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <h5 className={classes.mb0}> Check In Date* </h5>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <DatePicker
                                fullWidth
                                inputVariant={"outlined"}
                                format={"MM/DD/YYYY"}
                                variant={"inline"}
                                value={checkInDate}
                                onChange={this.handleCheckInDateChange}
                                autoOk
                                disablePast
                              />
                            </MuiPickersUtilsProvider>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <h5 className={classes.mb0}> Check Out Date* </h5>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <DatePicker
                                fullWidth
                                inputVariant={"outlined"}
                                format={"MM/DD/YYYY"}
                                variant={"inline"}
                                value={checkOutDate}
                                onChange={this.handleCheckOutDateChange}
                                autoOk
                                disablePast
                              />
                            </MuiPickersUtilsProvider>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <h5 className={classes.mb0}> No of People* </h5>
                            <TextField
                              fullWidth
                              name="noOfPeople"
                              variant="outlined"
                              value={values.noOfPeople}
                              size="small"
                              onChange={this.handleChange}
                            />
                            {errors.noOfPeople && touched.noOfPeople && (
                              <FormHelperText error>
                                {" "}
                                {errors.noOfPeople}{" "}
                              </FormHelperText>
                            )}
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ textAlign: "center", marginTop: 20 }}
                          >
                            <Button
                              fullWidth
                              color="rose"
                              onClick={handleSubmit}
                              type="submit"
                            >
                              Confirm
                            </Button>
                          </GridItem>
                        </GridContainer>
                        {isConfirm && (
                          <GridContainer
                            style={{
                              boxShadow: "3px 2px 8px #0003",
                              marginTop: "30px",
                            }}
                          >
                            <GridItem xs={12} sm={12} md={12}>
                              <p
                                style={{
                                  fontSize: "1.2rem",
                                  color: "#454243",
                                  marginTop: 15,
                                  fontWeight: "500",
                                }}
                              >
                                Sub Total:{" "}
                                <span
                                  style={{ fontWeight: "bold", marginLeft: 5 }}
                                >
                                  {" "}
                                  $ 1200{" "}
                                </span>{" "}
                              </p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <p
                                style={{
                                  fontSize: "1.2rem",
                                  color: "#454243",
                                  marginTop: 20,
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                You can add any extra facility you want from
                                here{" "}
                              </p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h5
                                  style={{ marginRight: 20, color: "#454243" }}
                                >
                                  Food ($40 PP)
                                </h5>
                                <Switch
                                  checked={foodChecked}
                                  onChange={this.handleAddonsOneChange}
                                  onColor="#86d3ff"
                                  onHandleColor="#2693e6"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  className="react-switch"
                                  id="material-switch"
                                />
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h5
                                  style={{ marginRight: 20, color: "#454243" }}
                                >
                                  Decorations ($500)
                                </h5>
                                <Switch
                                  checked={decorations}
                                  onChange={this.handleAddonsTwoChange}
                                  onColor="#86d3ff"
                                  onHandleColor="#2693e6"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  className="react-switch"
                                  id="material-switch"
                                />
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <h3
                                style={{
                                  textAlign: "center",
                                  marginTop: 40,
                                  fontWeight: "bold",
                                  fontSize: "1.6rem",
                                }}
                              >
                                Total:{" "}
                                <span style={{ marginLeft: 5 }}>$ {total}</span>
                              </h3>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <Button
                                color="rose"
                                onClick={this.goToPayment}
                                style={{ width: "100%" }}
                              >
                                Book Now
                              </Button>
                            </GridItem>
                          </GridContainer>
                        )}
                      </GridItem>
                    </GridContainer>
                  </form>
                )}
              </Formik>
            </div>
            {<Footer />}
          </div>
        </Suspense>
      </div>
    );
  }
}
BookStay.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookStay);
