/*eslint-disable*/
import React, { Suspense, Component } from "react";
import classNames from "classnames";
import MuiPhoneNumber from "material-ui-phone-number";
import { TextField, FormHelperText, Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { Helmet } from "react-helmet";
import { isBrowser, isTablet, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() =>
  import("components/Header/HeaderLinks.js")
);
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const GridContainer = React.lazy(() => import("components/Grid/GridContainer"));
const GridItem = React.lazy(() => import("components/Grid/GridItem"));
const Button = React.lazy(() => import("components/CustomButtons/Button.js"));
// import StripePayment from "./StripePayment.js";

import styles from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import Alert from "@material-ui/lab/Alert";

class BookNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      address1: "",
      address2: "",
      notes: "",
      promoCode: "",
      roomDetails: props.location.state && props.location.state.roomDetails,
      total: props.location.state && props.location.state.total,
      checkInDate: props.location.state && props.location.state.checkInDate,
      checkOutDate: props.location.state && props.location.state.checkOutDate,
      adults: props.location.state && props.location.state.adults,
      fromEvent: props.location.state && props.location.state.fromEvent,
      fromCustomEvent:
        props.location.state && props.location.state.fromCustomEvent,
      number: "",
      cvc: "",
      expiry: "",
      name: "",
      snackbarOpen: false,
      variant: "error",
      response: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCardInputChange = (e) => {
    if (e.target.name === "number") {
      this.setState({
        number: formatCreditCardNumber(e.target.value),
      });
    } else if (e.target.name === "expiry") {
      this.setState({
        expiry: formatExpirationDate(e.target.value),
      });
    } else if (e.target.name === "cvc") {
      this.setState({
        cvc: formatCVC(e.target.value),
      });
    }
  };

  selectCountry = (val) => {
    this.setState({ country: val });
  };

  makeReservation = (values) => {
    const { checkInDate, checkOutDate, roomDetails } = this.state;
    const guestInfo = {
      Name: values.firstName + " " + values.lastName,
      Email: values.email,
      Phone: values.phone,
    };
    const bookingData = {
      Arrival: checkInDate,
      Departure: checkOutDate,
      Rooms: roomDetails,
      Guest: guestInfo,
    };
    const uriOne = encodeURIComponent("check_in_from" + checkInDate);
    const uriTwo = encodeURIComponent("to_check_out" + checkOutDate);
    this.props.history.push(`/payment/${uriOne}/${uriTwo}`, {
      data: this.state,
    });
  };

  handleCloseSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleSnackbarOpenSuccess = () => {
    this.setState({
      snackbarOpen: true,
      variant: "success",
      response: "Payment done successfully!",
    });
  };

  handleSnackbarOpenError = (message) => {
    this.setState({
      snackbarOpen: true,
      variant: "error",
      response: message,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      email,
      phone,
      address1,
      notes,
      promoCode,
      roomDetails,
      total,
      checkInDate,
      checkOutDate,
      adults,
      // country,
      address2,
      snackbarOpen,
      variant,
      response,
      fromEvent,
      // fromCustomEvent,
    } = this.state;
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>The Gaston | Dallas | Book Stay</title>
            <meta
              name="description"
              content="Welcome to The Gaston, a unique bed & breakfast located in Old East Dallas. The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
            />
            <meta
              name="keywords"
              content="The Gaston, Dallas, House, Old East Dallas"
            />
            <meta name="og:title" property="og:title" content="The Gaston" />
            <meta
              name="og:description"
              property="og:description"
              content="The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
            />
            <meta
              name="og:image"
              property="og:image"
              content="https://www.thegaston.com/Rooms/the_gaston_outside-9.jpg"
            />
          </Helmet>

          <Header
            links={<HeaderLinks dropdownHoverColor="info" />}
            fixed
            color="white"
            changeColorOnScroll={{
              height: 100,
              color: "white",
            }}
          />

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snackbarOpen}
            onClose={this.handleCloseSnackbar}
            autoHideDuration={2000}
          >
            <Alert severity={variant}>{response}</Alert>
          </Snackbar>

          <div
            className={classNames(
              classes.main,
              classes.mainRaised,
              isBrowser || isTablet
                ? classes.stayBlockMarginTop
                : classes.stayBlockMarginTopMobile
            )}
          >
            <div
              className={classes.container}
              style={{ paddingBottom: 50, paddingTop: 50 }}
            >
              {isMobile && (
                <h2 className={classes.bookingTitle}> The Gaston Booking </h2>
              )}
              <div className={classes.bookingDetailsMainDiv}>
                <GridContainer justify="center">
                  <GridItem sm={12} xs={12} md={4} lg={4}>
                    <div className={classes.bookingSideDiv}>
                      <div>
                        <img src="/the_gaston_outside-001.jpg" />
                      </div>
                      <div
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingBottom: 30,
                          marginBottom: 20,
                        }}
                      >
                        {!fromEvent ? (
                          <h5> Your Reservation </h5>
                        ) : (
                          <h5> Event Booking Details </h5>
                        )}
                        <GridContainer justify="center">
                          <GridItem
                            sm={6}
                            md={6}
                            lg={6}
                            style={{ marginTop: 30 }}
                          >
                            <div>
                              <h3>{checkInDate}</h3>
                              <p> Check-in </p>
                            </div>
                          </GridItem>
                          <GridItem
                            sm={6}
                            md={6}
                            lg={6}
                            style={{ marginTop: 30 }}
                          >
                            <div>
                              <h3>{checkOutDate}</h3>
                              <p> Check-out </p>
                            </div>
                          </GridItem>
                          {!fromEvent && (
                            <>
                              <GridItem
                                sm={6}
                                md={6}
                                lg={6}
                                style={{ marginTop: 30 }}
                              >
                                <div>
                                  <h3> {roomDetails && roomDetails.length} </h3>
                                  <p> Room(s) </p>
                                </div>
                              </GridItem>
                              <GridItem
                                sm={6}
                                md={6}
                                lg={6}
                                style={{ marginTop: 30 }}
                              >
                                <div>
                                  <h3> {adults} </h3>
                                  <p> Guest(s) </p>
                                </div>
                              </GridItem>
                            </>
                          )}
                          <GridItem
                            sm={10}
                            md={10}
                            lg={10}
                            style={{ marginTop: 30 }}
                          >
                            <div>
                              {/* {!fromEvent ? ( */}
                              <h3> $ {Math.round(total)}.00 </h3>
                              {/* 1650 */}
                              <p> Total </p>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem sm={12} xs={12} md={8} lg={8}>
                    <Formik
                      validationSchema={Yup.object().shape({
                        firstName: Yup.string().required(
                          "First name is required"
                        ),
                        lastName: Yup.string().required(
                          "Last name is required"
                        ),
                        email: Yup.string()
                          .required("Email is required")
                          .email("Email is Invalid"),
                        phone: Yup.string().required(
                          "Phone number is required"
                        ),
                      })}
                      initialValues={{
                        firstName,
                        lastName,
                        email,
                        phone,
                      }}
                      onSubmit={(values) => {
                        this.makeReservation(values);
                      }}
                    >
                      {({
                        errors,
                        values,
                        touched,
                        handleSubmit,
                        handleChange,
                      }) => (
                        <div>
                          <form onSubmit={handleSubmit}>
                            <div>
                              <h2> Add Your Information </h2>
                              <GridContainer>
                                <GridItem sm={12} md={6} lg={6}>
                                  <h5 className={classes.mb0}> First Name* </h5>
                                  <TextField
                                    fullWidth
                                    name="firstName"
                                    type="text"
                                    margin="dense"
                                    variant="outlined"
                                    placeholder="Enter first name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                  />
                                  {errors.firstName && touched.firstName && (
                                    <FormHelperText error>
                                      {errors.firstName}
                                    </FormHelperText>
                                  )}
                                </GridItem>
                                <GridItem sm={12} md={6} lg={6}>
                                  <h5 className={classes.mb0}> Last Name* </h5>
                                  <TextField
                                    fullWidth
                                    placeholder="Enter last name"
                                    name="lastName"
                                    type="text"
                                    margin="dense"
                                    variant="outlined"
                                    value={values.lastName}
                                    onChange={handleChange}
                                  />
                                  {errors.lastName && touched.lastName && (
                                    <FormHelperText error>
                                      {errors.lastName}
                                    </FormHelperText>
                                  )}
                                </GridItem>
                                <GridItem sm={12} md={6} lg={6}>
                                  <h5 className={classes.mb0}> Email* </h5>
                                  <TextField
                                    fullWidth
                                    name="email"
                                    placeholder="Enter email"
                                    type="text"
                                    margin="dense"
                                    variant="outlined"
                                    value={values.email}
                                    onChange={handleChange}
                                  />
                                  {errors.email && touched.email && (
                                    <FormHelperText error>
                                      {errors.email}
                                    </FormHelperText>
                                  )}
                                </GridItem>
                                <GridItem sm={12} md={6} lg={6}>
                                  <h5 className={classes.mb0}> Phone* </h5>
                                  <MuiPhoneNumber
                                    fullWidth
                                    name="phone"
                                    placeholder="Enter phone number"
                                    variant="outlined"
                                    disableAreaCodes={false}
                                    onBlur={handleChange}
                                    defaultCountry={"us"}
                                    onChange={handleChange}
                                    countryCodeEditable={true}
                                    value={values.phone}
                                    margin="dense"
                                  />
                                  {errors.phone && touched.phone && (
                                    <FormHelperText error>
                                      {errors.phone}
                                    </FormHelperText>
                                  )}
                                </GridItem>
                                {/* <GridItem sm={12} md={6} lg={6}>
                                <h5 className={classes.mb0}> Check-In Time </h5>
                                <CountryDropdown
                                  value={country}
                                  onChange={val => this.selectCountry(val)}
                                  className="countryDropdown"
                                />
                              </GridItem> */}
                                <GridItem sm={12} md={12} lg={12}>
                                  <h5 className={classes.mb0}>
                                    {" "}
                                    Address Line 1
                                  </h5>
                                  <TextField
                                    fullWidth
                                    placeholder="Enter address line 1"
                                    name="address1"
                                    type="text"
                                    margin="dense"
                                    variant="outlined"
                                    value={address1}
                                    onChange={this.handleChange}
                                  />
                                </GridItem>
                                <GridItem sm={12} md={12} lg={12}>
                                  <h5 className={classes.mb0}>
                                    {" "}
                                    Address Line 2{" "}
                                  </h5>
                                  <TextField
                                    fullWidth
                                    placeholder="Enter address line 2"
                                    name="address2"
                                    type="text"
                                    margin="dense"
                                    variant="outlined"
                                    value={address2}
                                    onChange={this.handleChange}
                                  />
                                </GridItem>
                                <GridItem sm={12} md={12} lg={12}>
                                  <h5 className={classes.mb0}>
                                    {" "}
                                    Notes, Special Requests{" "}
                                  </h5>
                                  <TextField
                                    fullWidth
                                    placeholder="Enter notes and special request"
                                    name="notes"
                                    type="text"
                                    margin="dense"
                                    variant="outlined"
                                    value={notes}
                                    onChange={this.handleChange}
                                  />
                                </GridItem>
                                <GridItem sm={12} md={12} lg={12}>
                                  <h5 className={classes.mb0}> Promo Code </h5>
                                  <TextField
                                    fullWidth
                                    placeholder="Enter promo code"
                                    name="promoCode"
                                    type="text"
                                    margin="dense"
                                    variant="outlined"
                                    value={promoCode}
                                    onChange={this.handleChange}
                                  />
                                </GridItem>
                              </GridContainer>
                            </div>
                            <div className={classes.makeReservationButton}>
                              <Button
                                color="rose"
                                type="submit"
                                onClick={handleSubmit}
                              >
                                Pay $ {total}.00
                              </Button>
                            </div>
                          </form>
                        </div>
                      )}
                    </Formik>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            {<Footer />}
          </div>
        </Suspense>
      </div>
    );
  }
}
BookNow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookNow);
