/* eslint-disable import/first */
import React, { Suspense } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import CookieConsent from "react-cookie-consent";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import SectionHeaders from "./Sections/SectionHeader";
const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() =>
  import("components/Header/HeaderLinks.js")
);
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const GridContainer = React.lazy(() =>
  import("components/Grid/GridContainer.js")
);
const GridItem = React.lazy(() => import("components/Grid/GridItem.js"));
const SectionFeatures = React.lazy(() =>
  import("views/SectionsPage/Sections/SectionFeatures")
);
const SectionTestimonials = React.lazy(() =>
  import("views/SectionsPage/Sections/SectionTestimonials")
);
// import RoomsTabs from "./Sections/Tabs";
// import SectionInstagramFeed from "./Sections/InstagramFeed.js";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Header
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="white"
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
        <SectionHeaders />
        {/* <video
          autoPlay
          loop
          muted
          playsInline         
          className={isBrowser ? "videoHomepage" : "videoHomepage videoMobile"}
        >
          <source
            src={
              isMobile
                ? "https://d23l4wqnsivs21.cloudfront.net/30+sec+Front+page+The_Gaston_Dallas_bed_and_breakfast_+V2_opt_mobile.mp4"
                : "https://d23l4wqnsivs21.cloudfront.net/30+sec+Front+page+The_Gaston_Dallas_bed_and_breakfast_+V2_opt_desktop.mp4"
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video> */}

        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#454243" }}
          buttonStyle={{
            color: "#454243",
            fontSize: "15px",
            backgroundColor: "#fafaef",
            borderRadius: 20,
            padding: "10px 20px"
          }}
          declineButtonStyle={{
            border: "1px solid #fafaef",
            borderRadius: 20,
            color: "#fafaef",
            backgroundColor: "transparent",
            padding: "10px 20px"
          }}
          expires={150}
          enableDeclineButton={true}
          flipButtons={true}
        >
          <p>
            This website stores cookies on your computer. These cookies are used
            to collect information about how you interact with our website and
            allow us to remember you. We use this information in order to
            improve and customize your browsing experience and for analytics and
            metrics about our visitors both on this website and other media. If
            you decline, your information won’t be tracked when you visit this
            website. A single cookie will be used in your browser to remember
            your preference not to be tracked.
          </p>
        </CookieConsent>

        <h1 style={{ display: "none" }}> The Gaston House Dallas </h1>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div
            className="cd-section"
            style={{ padding: "10px 0", paddingBottom: "0" }}
          >
            <div className={classes.container}>
              <h2 className={classes.title} style={{ padding: "20px 0" }}>
                About Us
              </h2>
              <GridContainer justify="center">
                <GridItem md={10}>
                  <h5
                    className={classes.description}
                    style={{ textAlign: "justify" }}
                  >
                    Welcome to The Gaston, a unique bed & breakfast located in
                    Old East Dallas. The historic home was built in 1918 and is
                    conveniently located two miles from downtown. It is
                    sandwiched between the entertainment districts of Lower
                    Greenville and Deep Ellum. The Gaston has recently been
                    renovated by the Gibson Family to include five luxury
                    suites, indoor and outdoor event space, and a cocktail bar.
                    Rich in Dallas culture, the Gaston and its rooms have been
                    uniquely designed to represent a piece of local history. It
                    is sure to create lasting memories. We can’t wait for you to
                    visit.
                  </h5>
                </GridItem>
              </GridContainer>
            </div>
          </div>

          {/* <div
          className={classes.parallaxImg}
          style={{ backgroundImage: "url(" + BGImage + ")" }}
        >
          <GridContainer
            justify="center"
            style={{ position: "relative", zIndex: "10" }}
          >
            <GridItem
              xs={11}
              sm={11}
              md={11}
              lg={7}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title + " " + classes.titleWhite}>
                Rooms
              </h2>

              <RoomsTabs />
            </GridItem>
          </GridContainer>
        </div> */}
          {/* <SectionCards /> */}
          {/* <Gallery /> */}
          <SectionFeatures />
          <SectionTestimonials isGuestReview={true} />
          {/* <div className={classes.container}>
          <h2 className={classes.title}>Follow Us On Instagram</h2>
          <div style={{ marginBottom: 50, marginTop: 50 }}>
            <SectionInstagramFeed />
          </div>
        </div> */}
          <Footer
            content={
              <div>
                <div className={classes.right}>
                  &copy; {1900 + new Date().getYear()} , Made By The Gaston{" "}
                </div>
              </div>
            }
          />
        </div>
      </Suspense>
    </div>
  );
}
