import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import { ListItemText, Button, withStyles, Badge } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";

import styles from "../../assets/jss/material-kit-pro-react/components/headerLinksStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles(styles);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

export default function HeaderLinks(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bookingTotal, setBookingTotal] = React.useState(null);

  useEffect(() => {
    if (props.roomDetails && props.roomDetails.length > 0) {
      const semiTotal = props.roomDetails.reduce(
        (prev, next) => prev + next.max_price_per_night,
        0
      );
      const total = semiTotal + 350 + 72;
      setBookingTotal(Math.round(total));
    }
  }, [props.roomDetails]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  // function goToBookNow() {
  //   const { roomDetails } = props;
  //   props.history.push("/book-now", { roomDetails, bookingTotal })
  // }

  const classes = useStyles();
  const uriOne = encodeURIComponent(
    "check_in_from" + props.checkInDate + "to_check_out" + props.checkOutDate
  );
  const uriTwo = encodeURIComponent("?payment_total_" + btoa(bookingTotal));
  return (
    <div>
      {/* <MobileView>
        <div className={classes.topHeader}>
          <h6 style={{ textTransform: 'lowercase', display: 'flex', alignItems: 'center' }}>
            <EmailIcon style={{ fontSize: 14, marginRight: 10 }} />
            <a href="mailto: info@thegaston.com" style={{ color: 'inherit' }} > info@thegaston.com </a>
          </h6>
          <h6 className={classes.contact} style={{ display: 'flex', alignItems: 'center' }}>
            <PhoneIcon style={{ fontSize: 14, marginRight: 10 }} />
            <a href="tel:+1 (469) 631-1166" style={{ color: 'inherit' }}> +1 (469) 631-1166</a>
          </h6>
          <h6 className={classes.topHeaderLocation} style={{ display: 'flex', alignItems: 'center' }}>
            <a
              href="https://goo.gl/maps/wW6vCkT5oAxdsxJfA"
              target="_Blank"
              style={{ color: "inherit" }}
            >
              <RoomIcon style={{ fontSize: 14, marginRight: 10 }} />
              4802 Gaston Ave,
              Dallas, Texas 75246
            </a>
          </h6>
          <h4 style={{ display: 'flex', alignItems: 'center' }}>
            <a
              href="https://www.instagram.com/thegastondallas/"
              style={{ color: 'inherit', display: 'flex', alignItems: 'center', marginRight: 20 }}
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              href="https://www.facebook.com/TheGastonDallasTX"
              style={{ color: 'inherit', display: 'flex', alignItems: 'center' }}
              className={classes.topHeaderLocation}
            >
              <i className="fab fa-facebook" />
            </a>
          </h4>
        </div>
      </MobileView> */}
      <List
        className={
          classes.list +
          " " +
          classes.mlAuto +
          " " +
          classes.headerLink +
          " " +
          classes.justifyContent
        }
      >
        <ListItem className={classes.listItem}>
          <Link to="/rooms" className={classes.dropdownLink}>
            <ListItemText style={{ marginRight: "15px" }}> Rooms </ListItemText>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/gallery" className={classes.dropdownLink}>
            <ListItemText style={{ marginRight: "15px" }}>
              {" "}
              Gallery{" "}
            </ListItemText>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/events" className={classes.dropdownLink}>
            <ListItemText style={{ marginRight: "15px" }}>
              {" "}
              Events{" "}
            </ListItemText>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/city-culture" className={classes.dropdownLink}>
            <ListItemText style={{ marginRight: "15px" }}>
              {" "}
              City & Culture{" "}
            </ListItemText>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/blog" className={classes.dropdownLink}>
            <ListItemText style={{ marginRight: "15px" }}> Blog </ListItemText>
          </Link>
        </ListItem>
        {!isMobile && (
          <>
            {props.roomDetails && props.roomDetails.length > 0 && (
              <>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    style={{ marginRight: "15px" }}
                    onClick={handleClick}
                    className={classes.cursorPointer}
                  >
                    {" "}
                    <span> Check Reservation </span>
                    <Badge
                      badgeContent={props.roomDetails.length}
                      color="primary"
                      classes={{
                        badge: classes.customBadge
                      }}
                      className="shake-horizontal"
                    >
                      {" "}
                    </Badge>
                  </ListItemText>
                </ListItem>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className={classes.styledMenu}
                >
                  <div className={classes.reservationMenu}>
                    <GridContainer justify="center">
                      <GridItem
                        sm={6}
                        md={6}
                        className={classes.reservationDiv}
                      >
                        <h4>
                          {" "}
                          <DateRangeIcon /> Check In
                        </h4>
                        <p>{props.checkInDate}</p>
                      </GridItem>
                      <GridItem
                        sm={6}
                        md={6}
                        className={classes.reservationDiv}
                      >
                        <h4>
                          {" "}
                          <DateRangeIcon /> Check Out
                        </h4>
                        <p>{props.checkOutDate}</p>
                      </GridItem>
                    </GridContainer>
                    <hr className={classes.customHR} />
                    <GridContainer justify="center">
                      <GridItem sm={10} md={10}>
                        {props.roomDetails.map((room, index) => (
                          <div key={room.main_image.text + index}>
                            <GridContainer justify="center">
                              <GridItem
                                sm={6}
                                md={8}
                                className={classes.textAlignLeft}
                              >
                                <h4 className={classes.fontWeightNormal}>
                                  {" "}
                                  {room.main_image.text}{" "}
                                </h4>
                              </GridItem>
                              <GridItem sm={6} md={4}>
                                <h4 className={classes.fontWeightNormal}>
                                  {" "}
                                  {Math.round(room.max_price_per_night)}.00{" "}
                                </h4>
                              </GridItem>
                            </GridContainer>
                          </div>
                        ))}
                      </GridItem>
                      <GridItem sm={10} md={10}>
                        <GridContainer justify="center">
                          <GridItem
                            sm={6}
                            md={8}
                            className={classes.textAlignLeft}
                          >
                            <h4 className={classes.fontWeightNormal}> Fees </h4>
                          </GridItem>
                          <GridItem sm={6} md={4}>
                            <h4 className={classes.fontWeightNormal}>
                              {" "}
                              $350.00{" "}
                            </h4>
                          </GridItem>
                          <GridItem
                            sm={6}
                            md={8}
                            className={classes.textAlignLeft}
                          >
                            <h4 className={classes.fontWeightNormal}> Tax </h4>
                          </GridItem>
                          <GridItem sm={6} md={4}>
                            <h4 className={classes.fontWeightNormal}>
                              {" "}
                              $72.00{" "}
                            </h4>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    <hr className={classes.customHR} />
                    <GridContainer justify="center">
                      <GridItem sm={10} md={10}>
                        <GridContainer>
                          <GridItem
                            sm={6}
                            md={8}
                            className={classes.textAlignLeft}
                          >
                            <h4 className={classes.fontWeightMedium}>
                              {" "}
                              Total{" "}
                            </h4>
                          </GridItem>
                          <GridItem sm={6} md={4}>
                            <h4 className={classes.fontWeightMedium}>
                              {" "}
                              ${bookingTotal}.00{" "}
                            </h4>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem md={10}>
                        <Link
                          to={{
                            pathname: `/book-stay/${uriOne}/${uriTwo}`,
                            state: {
                              roomDetails: props.roomDetails,
                              total: bookingTotal,
                              checkInDate: props.checkInDate,
                              checkOutDate: props.checkOutDate,
                              adults: props.adults
                            }
                          }}
                          className={classes.dropdownLink}
                          style={{ paddingRight: "0px" }}
                        >
                          <Button
                            fullWidth
                            style={{
                              backgroundColor: "#b87333",
                              color: "#fff",
                              marginTop: 20,
                              marginBottom: 20
                            }}
                            // onClick={goToReservation}
                          >
                            Book Now
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </div>
                </StyledMenu>
              </>
            )}
          </>
        )}
        <ListItem className={classes.listItem}>
          <Link
            to="/book-stay"
            className={classes.dropdownLink}
            style={{ paddingRight: "0px" }}
          >
            <Button
              style={{
                backgroundColor: "#b87333",
                color: "#fff",
                padding: "4px 8px"
              }}
            >
              <ListItemText style={{ paddingRight: 25, paddingLeft: 25 }}>
                {" "}
                Stay{" "}
              </ListItemText>
            </Button>
          </Link>
        </ListItem>
      </List>
    </div>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "black"
  ])
};
