/* eslint-disable import/first */

import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";

const GridContainer = React.lazy(() =>
  import("components/Grid/GridContainer.js")
);
const GridItem = React.lazy(() => import("components/Grid/GridItem.js"));
const Button = React.lazy(() => import("components/CustomButtons/Button.js"));
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import WeddingImage from "assets/img/weddings/the_gaston_house_weddings_2.jpg";
import Img from "components/Image/Image";

const useStyles = makeStyles(presentationStyle);

export default function WeddingPage() { 
  const classes = useStyles();
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              xs={10}
              sm={10}
              md={10}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title} style={{ textAlign: "center" }}>
                {" "}
                Weddings
              </h2>

              <GridContainer className={classes.weddingCard}>
                <GridItem md={6} sm={12} xs={12} style={{ paddingLeft: "0px" }}>
                  <div className={classes.weddingInquiryContent}>
                    <div>
                      <h5 style={{ color: "#454243", fontWeight: "500" }}>
                        Weddings
                      </h5>
                      <p
                        className={classes.cardDetails}
                        style={{ marginTop: "20px" }}
                      >
                        With our gorgeous setting and personalized services,The
                        Gaston is a hidden treasure for brides looking for a
                        more intimate setting. With a versatile layout, we can
                        accommodate your ceremony, reception, and stay.
                      </p>
                    </div>
                    <Button
                      color="rose"
                      href="/wedding-inquiry"
                      className={classes.inquireButton}
                    >
                      {" "}
                      WEDDING INQUIRY{" "}
                    </Button>
                  </div>
                </GridItem>
                <GridItem md={6} sm={12} xs={12} className={classes.weddingImg}>
                  <Img
                    src={WeddingImage}
                    className={classes.fullWidth}
                    alt="The Gaston Wedding Event"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.weddingInquiryButton}
                ></GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>       
      </div>
    </Suspense>
  );
}
