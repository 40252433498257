/*eslint-disable*/
import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

const Header = React.lazy(() =>  import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() =>  import("components/Header/HeaderLinks.js"));
const Footer = React.lazy(() =>  import("components/Footer/Footer.js"));
const Parallax = React.lazy(() =>  import("components/Parallax/Parallax.js"));
const SectionBlog = React.lazy(() =>  import("../PresentationPage/Sections/SectionBlog.js"));
const SubscribeLine = React.lazy(() =>  import("./Sections/SubscribeLine.js"));

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import BlogBanner from "assets/img/blog/blog-banner.jpg";

const useStyles = makeStyles(blogPostsPageStyle);

export default function BlogPostsPage(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Gaston | Dallas | Blog</title>
        {/* <link rel="canonical" href="https://www.thegaston.com/events" /> */}
        <meta
          name="description"
          content="Welcome to The Gaston, a unique bed & breakfast located in Old East Dallas. The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
        />
        <meta
          name="keywords"
          content="The Gaston, Dallas, House, Old East Dallas"
        />
        <meta name="og:title" property="og:title" content="The Gaston" />
        <meta
          name="og:description"
          property="og:description"
          content="The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://www.thegaston.com/Rooms/the_gaston_outside-9.jpg"
        />
      </Helmet>

      <Header
        brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
      />
      <Parallax
        image={BlogBanner}
        className={classes.parallax}        
      />
      <div className={classes.main}>
        <div className={classes.container}>
          <h1 style={{ display: "none" }}> The Gaston - Blogs </h1>
          <SectionBlog {...props} />
        </div>
        <SubscribeLine />
        <Footer />
      </div>
      </Suspense>
    </div>
  );
}
