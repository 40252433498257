import {
  grayColor,
  whiteColor,
  mlAuto,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

import tooltip from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const headerLinksStyle = theme => ({
  list: {
    [theme.breakpoints.up("md")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row"
    },
    [theme.breakpoints.down("sm")]: {
      display: "block"
    },
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0"
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll"
      },
      width: "100%",
      "&:not(:last-child)": {
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: grayColor[14]
        }
      }
    }
  },
  listItemText: {
    padding: "0 !important",
    fontWeight: "500"
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit"
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px"
    }
  },
  navLinkJustIcon: {
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      marginRight: "0px"
    },
    "& svg": {
      marginRight: "0px"
    }
  },
  navButton: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "5px",
      marginTop: "5px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "center"
      }
    },
    "& $icons": {
      marginRight: "3px"
    },
    "&:hover": {
      boxShadow: "none"
    }
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex"
  },
  registerNavLink: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex"
  },
  navLinkActive: {
    "&, &:hover, &:focus,&:active ": {
      color: "inherit",
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.1)"
    }
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "14px"
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "14px",
    opacity: "0.5",
    marginTop: "-4px",
    top: "1px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative"
  },
  socialIcons: {
    position: "relative",
    fontSize: "1.25rem",
    maxWidth: "24px"
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "flex",
      padding: "0.75rem 1.25rem 0.75rem 0.75rem"
    }
  },
  headerLink: {
    color: "#454243"
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px"
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto"
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  listRoot: {
    fontWeight: "500"
  },
  topHeader: {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width:1024px)": {
      justifyContent: "start",
      flexWrap: "wrap",
      marginLeft: "15px",
      borderBottom: "1px solid lightgray",
      marginTop: 60
    },
    "& h6": {
      cursor: "pointer"
    }
  },
  contact: {
    marginLeft: 30,
    marginRight: 30,
    "@media (max-width:1024px)": {
      marginLeft: 0,
      marginRight: 0
    }
  },
  topHeaderLocation: {
    marginRight: 20,
    "@media (max-width:1024px)": {
      marginRight: 0
    }
  },
  mlAuto,
  justifyContent: {
    justifyContent: "flex-end"
  },
  reservationDiv: {
    textAlign: "center",
    "& p": {
      fontWeight: 500,
      color: "#b87333",
      fontSize: "17px"
    },
    "& h4": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "500",
      "& svg": {
        marginRight: 5,
        width: 20
      }
    }
  },
  customHR: {
    marginTop: 0,
    marginBottom: 0
  },
  cursorPointer: {
    cursor: "pointer"
  },
  reservationMenu: {
    minHeight: "300px",
    width: "400px"
  },
  fontWeightNormal: {
    fontWeight: "400"
  },
  fontWeightMedium: {
    fontWeight: "600"
  },
  styledMenu: {
    "& h4": {
      marginTop: 5,
      marginBottom: 5
    }
  },
  textAlignLeft: {
    textAlign: "left"
  },
  customBadge: {
    borderRadius: "0",
    background: "#454243",
    transform: "scale(1) translate(80%, -100%)"
  }
});

export default headerLinksStyle;
