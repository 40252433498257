/*eslint-disable*/

import React, { Suspense } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import { CardMedia } from "@material-ui/core";

const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() =>
  import("components/Header/HeaderLinks.js")
);
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const GridContainer = React.lazy(() =>
  import("components/Grid/GridContainer.js")
);
const GridItem = React.lazy(() => import("components/Grid/GridItem.js"));
const Button = React.lazy(() => import("components/CustomButtons/Button.js"));
const SectionWedding = React.lazy(() => import("views/Weddings/Weddings"));
const SectionHeader = React.lazy(() => import("./SectionHeader"));
const SocialShare = React.lazy(() => import("./SocialShare.js"));

// import PhotoShoot from "assets/img/photoshoot/photoshoot1.jpg";
// import Weddings from "assets/img/weddings/the_gaston_house_weddings_2.jpg";
import MysteryParty from "assets/img/event/the_gaston_mystery_party_detective.jpg";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function Event(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const goToEventDetails = uri => {
    const encodedUri = btoa(uri);
    props.history.push(`/book-stay/?=${encodedUri}`);
  };

  function goToCustomEventBooking() {
    props.history.push("/book-events");
  }

  const event1 =
    window.location.origin +
    "/static/media/the_gaston_mystery_party_detective.jpg";

  const event2 = window.location.origin + "/Tours/GastonBar-2.jpg";

  const classes = useStyles();
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Gaston | Dallas | Events</title>
          {/* <link rel="canonical" href="https://www.thegaston.com/events" /> */}
          <meta
            name="description"
            content="Welcome to The Gaston, a unique bed & breakfast located in Old East Dallas. The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
          />
          <meta
            name="keywords"
            content="The Gaston, Dallas, House, Old East Dallas"
          />
          <meta name="og:title" property="og:title" content="The Gaston" />
          <meta
            name="og:description"
            property="og:description"
            content="The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
          />
          <meta
            name="og:image"
            property="og:image"
            content="https://www.thegaston.com/Rooms/the_gaston_outside-9.jpg"
          />
        </Helmet>

        <Header
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="white"
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
        <SectionHeader />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1 style={{ display: "none" }}> The Gaston - Event </h1>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} md={12}>
                <h2 className={classes.title}>EVENTS AT THE GASTON</h2>
                <h5
                  className={
                    classes.description + " " + classes.eventDescription
                  }
                >
                  From dinner parties to bridal showers and weddings, we would
                  love to host your next event.
                </h5>
                <h5
                  className={
                    classes.description + " " + classes.eventDescription
                  }
                >
                  Please email{" "}
                  <a
                    href="mailto: info@thegaston.com"
                    className={classes.emailLink}
                  >
                    {" "}
                    info@thegaston.com{" "}
                  </a>{" "}
                  for more information on our custom event packages.
                </h5>
                <h5
                  className={
                    classes.description + " " + classes.eventDescription
                  }
                >
                  In addition, we host a variety of our own events open to the
                  public. Check out what's coming up below.
                </h5>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center" style={{ marginTop: "50px" }}>
              <GridItem sm={12} md={5} className={classes.paddingGrid}>
                <div
                  style={{
                    backgroundImage: "url('/../../../event/event.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                  }}
                  className={classes.bgimage}
                >
                  <div className={classes.eventCard}>
                    <div>
                      <p className={classes.eventCardTitle}>
                        You can book Gaston House and schedule your own events.
                      </p>
                    </div>
                  </div>
                </div>
                <Button
                  onClick={goToCustomEventBooking}
                  className={classes.eventButton}
                  color="rose"
                >
                  Schedule Your Events
                </Button>
              </GridItem>
            </GridContainer>
          </div>

          <div className={classes.eventParallaxImg}>
            <GridContainer
              justify="center"
              className={classes.upcomingEventGrid}
            >
              <GridItem
                xs={11}
                sm={11}
                md={11}
                lg={7}
                className={
                  classes.mlAuto +
                  " " +
                  classes.mrAuto +
                  " " +
                  classes.textCenter +
                  " "
                }
              >
                <h2
                  className={classes.title + " " + classes.upcomingEventTitle}
                  id="UpcomingEvents"
                >
                  Upcoming Events
                </h2>
                {/* <h4 className={classes.description + " " + classes.colorWhite}>
                Catch up with Gaston at Upcoming Events
              </h4> */}

                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={5}
                    md={4}
                    // className={classes.paddingGrid}
                  >
                    <Card classes={{ root: classes.upcomingEventCard }}>
                      <div>
                        <CardHeader
                          title="Murder Mystery Dinner"
                          subheader="Coming Soon"
                          classes={{ title: classes.upcomingEventCardHeader }}
                        />
                        <CardMedia
                          className={classes.eventMedia}
                          image={MysteryParty}
                          title=""
                        />
                        <CardContent>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            Be part of the thrilling plot as you and other
                            guests find clues, trade information, and watch
                            events unfold to solve the crime of the night... all
                            while sipping cocktails and enjoying a delicious
                            dinner.
                          </Typography>
                        </CardContent>
                      </div>
                      <CardActions className={classes.cardActions}>
                        <SocialShare
                          title={"Murder Mystery Dinner"}
                          image={event1}
                        />
                        <div>
                          <Button
                            onClick={() =>
                              goToEventDetails("Murder Mystery Dinner")
                            }
                            color="rose"
                            // size="sm"
                            className={classes.upcomingEventButtons}
                          >
                            Book Now
                          </Button>
                          {/* <Button
                            color="rose"
                            size="sm"
                            className={classes.upcomingEventButtons}
                          >
                            Coming Soon
                          </Button> */}
                        </div>
                      </CardActions>
                    </Card>
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={5}
                    md={4}
                    // className={classes.paddingGrid}
                  >
                    <Card classes={{ root: classes.upcomingEventCard }}>
                      <div>
                        <CardHeader
                          title="Speakeasy At The Gaston"
                          subheader="Coming Soon"
                          classes={{ title: classes.upcomingEventCardHeader }}
                        />
                        <CardMedia
                          className={classes.eventMedia}
                          image={"/../../../Tours/GastonBar-2.jpg"}
                          title=""
                        />
                        <CardContent>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            A chance to experience Dallas' newest hidden gem.
                            We'll be serving creative drinks in our cocktail bar
                            utilizing the favorite spirits of the Prohibition
                            era: gin, bourbon, and rum. But keep it a secret.
                            Like during the prohibition era, this exclusive
                            party is invite only!
                          </Typography>
                        </CardContent>
                      </div>
                      <CardActions className={classes.cardActions}>
                        <SocialShare
                          title={"Speakeasy At The Gaston"}
                          image={event2}
                        />
                        <div>
                          <Button
                            onClick={() =>
                              goToEventDetails("Speakeasy At The Gaston")
                            }
                            color="rose"
                            // size="sm"
                            className={classes.upcomingEventButtons}
                          >
                            Book Now
                          </Button>
                          {/* <Button
                            color="rose"
                            size="sm"
                            className={classes.upcomingEventButtons}
                          >
                            Coming Soon
                          </Button> */}
                        </div>
                      </CardActions>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>

          <div>
            <SectionWedding />
          </div>
          {/* <SectionFreeDemo /> */}

          <Footer />
        </div>
      </Suspense>
    </div>
  );
}
