
/* eslint-disable import/first */

import React, { Suspense } from "react";

const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() => import("components/Header/HeaderLinks.js"));
const Parallax = React.lazy(() => import("components/Parallax/Parallax.js"));
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const GridItem = React.lazy(() => import("components/Grid/GridItem"));
const GridContainer = React.lazy(() => import("components/Grid/GridContainer"));
const Card = React.lazy(() => import("./CardComponent"));

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import { Helmet } from "react-helmet";
import Banner from "assets/img/city-culture/city-culture-banner.jpg"
import Restaurants from "assets/img/city-culture/restaurants.jpeg"
import Bars from "assets/img/city-culture/bars-and-nightlife.jpeg"
import LiveMusic from "assets/img/city-culture/breweries.jpg"
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function CityCulture() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Gaston | Dallas | City & Culture</title>
        {/* <link rel="canonical" href="https://www.thegaston.com/events" /> */}
        <meta name="description" content="Welcome to The Gaston, a unique bed & breakfast located in Old East Dallas. The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."/>
        <meta name="keywords" content="The Gaston, Dallas, House, Old East Dallas" />
        <meta name="og:title" property="og:title" content="The Gaston" />
        <meta name="og:description" property="og:description" content="The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history." />
        <meta name="og:image" property="og:image" content="https://www.thegaston.com/Rooms/the_gaston_outside-9.jpg" />
      </Helmet>

      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
      />
      <Parallax
        image={Banner}
        className={classes.parallax}       
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
      <h1 style={{ display: "none" }}> The Gaston - City and Culture </h1>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem sm={10} md={10}>
              <h2 className={classes.title}> City & Culture </h2>
              <div className={classes.content}>
                <h4 className={classes.description}>
                  East Dallas is rich with culture both old and new; after the annexation, entrepreneurs and creatives occupied then shabby warehouses, turning them into clubs and venues, bringing culture and many social scenes that still thrive today.
                </h4>

                <h4 className={classes.description}>
                  Our home is centrally located and less than 2 miles from Uptown, Deep Ellum, Lower Greenville, and Knox Henderson.
                </h4>

                <h4 className={classes.description}>
                  Read on to learn more about some of our favorite spots to check out during your visit to The Gaston.
                </h4>
              </div>
            </GridItem>
          </GridContainer>
          <div>
            <GridContainer justify="center">
              <GridItem sm={10} md={10} className={classes.cityGrid}>
                <div
                  style={{ backgroundImage: "url(" + Restaurants + ")" }}
                  className={classes.bgImageCity}>
                  <div className={classes.cityTitleCard}>
                    <h3>
                      {/* RESTAURANTS */}
                      EATS
                      </h3>
                  </div>
                </div>
              </GridItem>
              <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'EATS'} subtitle1={'Bob’s Steak & Chop House'} content1={'The best (and original) steakhouse in Dallas.'} subtitle2={'Town Hearth'} content2={'According to the Dallas News, it may be the swankiest, most fabulous steakhouse on the planet.  We happen to agree.'} subtitle3={'Flora Street Café'} content3={'A destination from star chef Stephen Pyles, offering delicious dishes that combine Texas ingredients and modern techniques.'} link1={'https://www.yelp.com/biz/bobs-steak-and-chop-house-dallas-2?osq=Bob%27s+Steak+%26+Chop+House'} link2={'https://www.yelp.com/biz/town-hearth-dallas?osq=town+hearth'} link3={'https://www.yelp.com/biz/stephan-pyles-flora-street-cafe-dallas-2?osq=flora+street+cafe'} />
              </GridItem>
              <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'EATS'} subtitle1={'Stackhouse'} subtitle2={'Carbone’s'} subtitle3={'Two Sisters'} content1={'Known for their burgers, but we love their salads too.'} content2={'Our favorite Italian spot with incredible homemade pasta.'} content3={'Just down the street; the perfect spot to grab a quick lunch or order larger meals ahead for bigger groups.'} link1={'https://www.yelp.com/biz/stackhouse-dallas?osq=stackhouse'} link2={'https://www.yelp.com/biz/carbones-dallas?osq=carbone%27s'} link3={'https://www.yelp.com/biz/two-sisters-catering-dallas-4'} />
              </GridItem>
              {/* <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'CASUAL'} subtitle1={'STACKHOUSE'} subtitle2={'PUBLIC SCHOOL '} subtitle3={'FEED COMPANY'} content1={'Known for their burgers, but the salads are killer too!'} content2={'Fun spot in Uptown, gets pretty lively at night.'} content3={'Sweet Bourbon lounge & eatery in Lower Greenville.'} link1={'http://www.stackhouseburgers.com/'} link2={'https://www.psontap.com/'} link3={'https://www.feedcompanyeatery.com/'} />
              </GridItem> */}
              <GridItem sm={10} xs={10} md={10}>
                <hr className={classes.hr} />
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem sm={10} md={10} className={classes.cityGrid}>
                <div
                  style={{ backgroundImage: "url(" + Bars + ")" }}
                  className={classes.bgImageCity}>
                  <div className={classes.cityTitleCard}>
                    <h3>
                      {/* BARS & NIGHTLIFE */}
                      Drinks
                    </h3>
                  </div>
                </div>
              </GridItem>
              <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'DRINKS'} subtitle1={'Bowen House'} content1={'Upscale craft cocktail bar located in a Texas Landmark.'} subtitle2={'Backyard'} content2={'Huge patio that’s perfect for watching sports and playing games.'} subtitle3={'Skellig'} content3={'Irish-influenced pub with outdoor patio space and the best cold brew Irish coffee.'} link1={'https://www.yelp.com/biz/bowen-house-dallas-2?osq=bowen+house'} link2={'https://www.yelp.com/biz/backyard-dallas-4'} link3={'https://www.yelp.com/biz/the-skellig-dallas'}/>
              </GridItem>
              <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'DRINKS'} subtitle1={'Katy Trail'} content1={'Outdoor beer garden with over 50 beers on tap along the Katy Trail.'} subtitle2={'Truck Yard'} content2={'Retro outdoor space with a treehouse, trailer bars, and our favorite cheesesteak.'} link1={'https://www.yelp.com/biz/katy-trail-ice-house-dallas-3'} link2={'https://www.yelp.com/biz/truck-yard-dallas'} />
              </GridItem>
              {/* <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'CLUBS'} subtitle1={'THEORY'} content1={' Located in Uptown, Saturdays are the busiest.'} subtitle2={'CANDLEROOM'} content2={'Wednesday is their busiest night, plan ahead.'} subtitle3={'BOTTLED BLONDE'} content3={'Large rooftop, busiest on the weekends.'} />
              </GridItem>
              <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'BREWERIES'} subtitle1={'BISHOP CIDERCADE'} content1={'Free arcade games and all the ciders you love!'} subtitle2={'TEXAS ALE PROJECT '} content2={'Located in the Design District, inquire for tours.'} subtitle3={'DEEP ELLUM BREWING'} content3={'Very popular, book a tour, over in Deep Ellum.'} />
              </GridItem>
              <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'INTIMATE & COZY'} subtitle1={'THE STANDARD POUR'} content1={'Excellent Bourbon selection, get in early.'} subtitle2={'BOWEN HOUSE '} content2={'Super small, but amazing craft cocktails.'} subtitle3={'WAH WAH ROOM'} content3={' A speakeasy, keep an eye out for the pink sign.'} />
              </GridItem> */}
              <GridItem sm={10} xs={10} md={10}>
                <hr className={classes.hr} />
              </GridItem>
            </GridContainer>

            <GridContainer justify="center" >
              <GridItem sm={10} md={10} className={classes.cityGrid}>
                <div
                  style={{ backgroundImage: "url(" + LiveMusic + ")" }}
                  className={classes.bgImageCity}>
                  <div className={classes.cityTitleCard}>
                    <h3>
                      Breweries
                    </h3>
                  </div>
                </div>
              </GridItem>
              <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'Breweries'} subtitle1={'Community Beer Co. '} content1={'Award-winning craft beer, complete with cornhole.'} subtitle2={'Deep Ellum Brewing Co.'} content2={'Largest independent brewery in North Texas, and proud home of the Dallas Blonde and Deep Ellum IPA. '} />
              </GridItem>
              {/* <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'Breweries'} subtitle1={'TREES'} subtitle2={'LOUIE LOUIES'} subtitle3={'THE BALCONY CLUB'} content1={'A small venue, with different local artists in Deep Ellum.'} content2={'Dueling piano bar that won over Deep Ellum.'} content3={'Jazz music is king and locals love it!'} />
              </GridItem> */}
              {/* <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'LOCAL BANDS'} subtitle1={'THE RUSTIC'} subtitle2={'ADAIRS'} subtitle3={'DADA'} subtitle4={'THE CLOVER CLUB'} content1={'Americana and Country music inside/outside Uptown.'} content2={'Small dive hosting country artists in Deep Ellum.'} content3={'Small club in Deep Ellum with unique bands.'} content4={'Grab dinner and listen to local swing music.'} />
              </GridItem> */}
              <GridItem sm={10} xs={10} md={10}>
                <hr className={classes.hr} />
              </GridItem>
            </GridContainer>

            {/* <GridContainer justify="center" style={{ marginBottom: 50 }}>
              <GridItem sm={10} md={10} className={classes.cityGrid}>
                <div
                  style={{ backgroundImage: "url(" + Shopping + ")" }}
                  className={classes.bgImageCity}>
                  <div className={classes.cityTitleCard}>
                    <h3>
                      SHOPPING
                    </h3>
                  </div>
                </div>
              </GridItem>
              <GridItem sm={6} xs={12} md={3} className={classes.cityGrid + ' ' + classes.cityGridShadow}>
                <Card title={'SHOPPING'} subtitle1={'WEST VILLAGE'} content1={'Great boutiques & restaurants in Uptown.'} subtitle2={'DOLLY PYTHON '}
                  content2={' Cool vintage shop with local vendors in Deep Ellum.'} subtitle3={'HIGHLAND VILLAGE'} content3={'Upscale shops & Dining with major designers.'}
                  subtitle4={'KNOX-HENDERSON'} content4={'Super walkable area with unique shops of all kinds.'} subtitle5={'FARMERS MARKET'}
                  content5={'We love our farmers market, a must go! '}
                  link1={'https://westvillagedallas.com/'}
                  link2={'http://dollypythonvintage.com/'} link3={'https://hpvillage.com/'}
                  link4={'https://www.10best.com/destinations/texas/dallas/shopping/knox-hendersons-best-shopping/'} link5={'https://dallasfarmersmarket.org/'} />
              </GridItem>
            </GridContainer> */}

          </div>
        </div>


        <Footer />
      </div>
      </Suspense>
    </div>
  );
}
