/* eslint-disable import/first */
import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Helmet from "react-helmet";

const ImageGallery = React.lazy(() => import("./ImageGallery"));
const Header = React.lazy(() => import("components/Header/Header"));
const HeaderLinks = React.lazy(() => import("components/Header/HeaderLinks"));
const Footer = React.lazy(() => import("components/Footer/Footer"));
import { title } from "assets/jss/material-kit-pro-react.js";

const styles = {
  mainRaised: {
    "@media (max-width: 576px)": {
      marginTop: "-30px"
    },
    "@media (max-width: 830px)": {
      marginLeft: "10px",
      marginRight: "10px"
    },
    margin: "-60px 30px 30px",
    borderRadius: "6px",
    boxShadow: "1px 1px 9px 1px #00000026"
  },
  main: {
    background: "white",
    position: "relative",
    zIndex: "3"
  },
  mainDiv: {
    minHeight: "100vh",
    backgroundColor: "white"
  },
  container: {
    paddingTop: "50px",
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    paddingBottom: "150px",
    width: "100%",
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    }
  },
  description: {
    color: "gray",
    textAlign: "center"
  },
  title: {
    ...title
  },
  buttonDiv: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center"
  },
  subTitle: {
    textAlign: "center",
    marginBottom: 50,
    color: "#454243",
    fontSize: "12px",
    textTransform: "uppercase",
    fontWeight: "500"
  },
  customContainer: {
    marginTop: 120,
    "@media (max-width:768px)": {
      marginTop: 100
    }
  }
};

class Gallery extends React.Component {
  render() {
    const { classes } = this.props;
    const images = [
      {
        original: "Tours/FrontView - 1.jpg",
        thumbnail: "Tours/FrontView - 1.jpg",
        description: "The Gaston - Front Exterior",
        originalAlt: "The Gaston House Front view of Day",
        thumbnailAlt: "The Gaston House Front view of Day"
      },
      {
        original: "Tours/GastonDiningRoom-3.jpg",
        thumbnail: "Tours/GastonDiningRoom-3.jpg",
        description: "The Gaston - Dining Area",
        originalAlt: "The Gaston - Dining Area",
        thumbnailAlt: "The Gaston - Dining Area"
      },
      {
        original: "Tours/GastonLounge.jpg",
        thumbnail: "Tours/GastonLounge.jpg",
        description: "The Gaston - Lounge",
        originalAlt: "The Gaston - Lounge",
        thumbnailAlt: "The Gaston - Lounge"
      },
      {
        original: "Tours/The_Gaston_House_reading_room.jpg",
        thumbnail: "Tours/The_Gaston_House_reading_room.jpg",
        description: "The Gaston - Lounge",
        originalAlt: "The Gaston - Lounge",
        thumbnailAlt: "The Gaston - Lounge"
      },
      {
        original: "Tours/GastonRefectory.jpg",
        thumbnail: "Tours/GastonRefectory.jpg",
        description: "The Gaston - Refectory",
        originalAlt: "The Gaston - Refectory",
        thumbnailAlt: "The Gaston - Refectory"
      },
      {
        original: "Tours/GastonBar-3.jpg",
        thumbnail: "Tours/GastonBar-3.jpg",
        description: "The Gaston - Bar",
        originalAlt: "The Gaston - Bar",
        thumbnailAlt: "The Gaston - Bar"
      },
      {
        original: "Tours/GastonBar-4.jpg",
        thumbnail: "Tours/GastonBar-4.jpg",
        description: "The Gaston - Bar",
        originalAlt: "The Gaston - Bar",
        thumbnailAlt: "The Gaston - Bar"
      },
      {
        original: "Tours/GastonDiningRoom-1.jpg",
        thumbnail: "Tours/GastonDiningRoom-1.jpg",
        description: "The Gaston - Dining Room",
        originalAlt: "The Gaston - Dining Room",
        thumbnailAlt: "The Gaston - Dining Room"
      },
      {
        original: "Tours/GastonDiningRoom-2.jpg",
        thumbnail: "Tours/GastonDiningRoom-2.jpg",
        description: "The Gaston - Dining Room",
        originalAlt: "The Gaston - Dining Room",
        thumbnailAlt: "The Gaston - Dining Room"
      },
      {
        original: "Tours/GastonParlor.jpg",
        thumbnail: "Tours/GastonParlor.jpg",
        description: "The Gaston - Parlor",
        originalAlt: "The Gaston - Parlor",
        thumbnailAlt: "The Gaston - Parlor"
      },
      {
        original: "Tours/GastonOutsideView-1.jpg",
        thumbnail: "Tours/GastonOutsideView-1.jpg",
        description: "The Gaston - Outside view",
        originalAlt: "The Gaston backside view of day",
        thumbnailAlt: "The Gaston backside view of day"
      },
      {
        original: "Tours/GastonOutsideView-2.jpg",
        thumbnail: "Tours/GastonOutsideView-2.jpg",
        description: "The Gaston - Outside view",
        originalAlt: "The Gaston backside view of day",
        thumbnailAlt: "The Gaston backside view of day"
      },
      {
        original: "Tours/GastonKitchen-1.jpg",
        thumbnail: "Tours/GastonKitchen-1.jpg",
        description: "The Gaston - Kitchen",
        originalAlt: "The Gaston - Kitchen",
        thumbnailAlt: "The Gaston - Kitchen"
      },
      {
        original: "Tours/GastonKitchen-2.jpg",
        thumbnail: "Tours/GastonKitchen-2.jpg",
        description: "The Gaston - Kitchen",
        originalAlt: "The Gaston - Kitchen",
        thumbnailAlt: "The Gaston - Kitchen"
      }
    ];
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Gaston | Dallas | Gallery</title>
          <meta
            name="description"
            content="Welcome to The Gaston, a unique bed & breakfast located in Old East Dallas. The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
          />
          <meta
            name="keywords"
            content="The Gaston, Dallas, House, Old East Dallas"
          />
          <meta name="og:title" property="og:title" content="The Gaston" />
          <meta
            name="og:description"
            property="og:description"
            content="The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
          />
          <meta
            name="og:image"
            property="og:image"
            content="https://www.thegaston.com/Rooms/the_gaston_outside-9.jpg"
          />
        </Helmet>
        <Header
          brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="white"
        />
        <div className={classes.main}>
          <div className={classes.container + " " + classes.customContainer}>
            {/* <h2 className={classes.title}> EXPLORE </h2> */}
            <h2 className={classes.title}> GALLERY </h2>
            <h4 className={classes.subTitle}>
              {" "}
              Take a look around our beautiful home and plan your next visit to
              Dallas{" "}
            </h4>
            <ImageGallery
              items={images}
              autoPlay={true}
              showNav
              slideInterval={120000}
            />
            {/* {!isMobile && (
              <div className="">
                <h2 className={classes.title} style={{ padding: "20px 0" }}>
                  Take a Virtual Tour
                </h2>
                <iframe
                  width="100%"
                  height="450"
                  src="https://www.google.com/maps/embed?pb=!4v1574794321175!6m8!1m7!1sCAoSLEFGMVFpcE9ZZ3kxMkJJVTJmSHh4ZkRzMEd6TW1ZY2J6Q0N2aV85Y2VPREww!2m2!1d32.7987657!2d-96.7706041!3f29.12!4f-18.959999999999994!5f0.7820865974627469"
                  frameBorder="0"
                  allowFullScreen
                  allow="vr"
                ></iframe>
              </div>
            )} */}
          </div>
        </div>
        <Footer />
      </Suspense>
    );
  }
}

Gallery.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Gallery);
