import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function PhotoShoot() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
      />
      <Parallax
        // image={("/../../../the_gaston_house.jpg")}
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              xs={10}
              sm={10}
              md={10}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>PhotoShoot</h2>
              <h5 className={classes.description} style={{ color: '#454243' }}>
                We love it when our guests document their stay with us, and share
                their memories with others. In order to maintain the privacy of
                our guests, and integrity of our brand, we ask that all
                professional shoots be scheduled. We offer several options to book
                your shoot with us, and ask that you follow our policies regarding
                photography.
            </h5>

              <h5 className={classes.description} style={{ marginBottom: '50px', color: '#454243' }}>
                Here are some photos of our space fellow artists and travelers
                have shared.
            </h5>

              <GridContainer>
                <GridContainer style={{ marginBottom: '50px', boxShadow: '1px 1px 3px 0px #0003', padding: '20px' }}>
                  <GridItem md={6} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
                    <h5 style={{ color: '#454243', fontWeight: '400' }}>
                      Lorem Ipsum
                    </h5>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      className={classes.cardDetails}
                      style={{ marginTop: '20px' }}
                    >
                      We love it when our guests document their stay with us, and share their memories with others. In order to maintain the privacy of our guests, and integrity of our brand, we ask that all professional shoots be scheduled. We offer several options to book your shoot with us, and ask that you follow our policies regarding photography.
                    </Typography>
                  </GridItem>
                  <GridItem md={6} sm={12} xs={12} className={classes.weddingImg}>
                    {/* <img src={Photo4} style={{ width: '100%' }} /> */}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginBottom: '50px', boxShadow: '1px 1px 3px 0px #0003', padding: '20px' }}>
                  <GridItem md={6} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
                    <h5 style={{ color: '#454243', fontWeight: '400' }}>
                      Lorem Ipsum
                    </h5>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      className={classes.cardDetails}
                      style={{ marginTop: '20px' }}
                    >
                      We love it when our guests document their stay with us, and share their memories with others. In order to maintain the privacy of our guests, and integrity of our brand, we ask that all professional shoots be scheduled. We offer several options to book your shoot with us, and ask that you follow our policies regarding photography.
                    </Typography>
                  </GridItem>
                  <GridItem md={6} sm={12} xs={12} className={classes.weddingImg}>
                    {/* <img src={Photo2} style={{ width: '100%' }} /> */}
                  </GridItem>
                </GridContainer>
                <GridItem md={4} sm={12} style={{ marginBottom: 60 }}>
                  {/* <img style={{ width: '100%', marginTop: 20 }} src={Photo3} /> */}
                </GridItem>
                <GridItem md={4} sm={12} style={{ marginBottom: 60 }}>
                  {/* <img style={{ width: '100%', marginTop: 20 }} src={Photo2} /> */}
                </GridItem>
                <GridItem md={4} sm={12} style={{ marginBottom: 60 }}>
                  {/* <img style={{ width: '100%', marginTop: 20 }} src={Photo4} /> */}
                </GridItem>

                <GridItem sm={12} md={12} style={{ marginBottom: 60, textAlign: 'center' }}>
                    <Button color="rose" href="/photoshoot-inquiry" className={classes.inquireButton}> PHOTOSHOOT INQUIRY </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>

        <Footer
          content={
            <div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , Made By The Gaston{" "}
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
