 const RoomData = [
  {
    title: "Dealey",
    description:
      "Named for Dealey Plaza, one of the most significant landmarks in Dallas history. The site marks the birthplace of Dallas and is most well-known for the assassination of President Kennedy in 1963. Today, it remains a National Historic Landmark and a must-see while visiting Dallas. This elegant room features a king bed with plush bedding, full bathroom with a spa shower, and five large windows that boast natural sunlight.",
    images: [     
      {
        url: "/../../../Rooms/Dealey/Dealey-8.jpg"
      },
      {
        url: "/../../../Rooms/Dealey/Dealey-5.jpg"
      },
      {
        url: "/../../../Rooms/Dealey/Dealey-1.jpg"
      },
      {
        url: "/../../../Rooms/Dealey/Dealey-2.jpg"
      },
      {
        url: "/../../../Rooms/Dealey/Dealey-3.jpg"
      },
      {
        url: "/../../../Rooms/Dealey/Dealey-4.jpg"
      },

      {
        url: "/../../../Rooms/Dealey/Dealey-6.jpg"
      },
      {
        url: "/../../../Rooms/Dealey/Dealey-9.jpg"
      }
    ]
  },
  {
    title: "Adair",
    description:
      "Named for “Will” Penn Adair Rogers, who started his life as a cowhand and went on to become a stage and motion-picture star. He is still regarded as one of the greatest ropers of all time, and he first became a cowboy in the Texas Panhandle. This cowboy-style room has a large king bed with a spacious bathroom and a spa shower.",
    images: [
      {
        url: "/../../../Rooms/Adair/Adair-1.jpg"
      },
      {
        url: "/../../../Rooms/Adair/Adair-7.jpg"
      },
      {
        url: "/../../../Rooms/Adair/Adair-2.jpg"
      },
      {
        url: "/../../../Rooms/Adair/Adair-3.jpg"
      },
      {
        url: "/../../../Rooms/Adair/Adair-4.jpg"
      },      
      {
        url: "/../../../Rooms/Adair/Adair-6.jpg"
      }
    ]
  },
  {
    title: "Varco",
    description:
      "Named for one of the biggest providers of equipment to the oil and gas industry. The discovery of the large oil reserves in the 1920s established Dallas as the financial center of the oil industry. This modern-industrial room features a unique steel sliding door to a full bathroom with a spa shower, king bed with luxury linens, and large floor-to-ceiling windows.",
    images: [
      {
        url: "/../../../Rooms/Varco/Varco-4.jpg"
      },
      {
        url: "/../../../Rooms/Varco/Varco-5.jpg"
      },
      {
        url: "/../../../Rooms/Varco/Varco-1.jpg"
      },
      {
        url: "/../../../Rooms/Varco/Varco-2.jpg"
      },
      {
        url: "/../../../Rooms/Varco/Varco-3.jpg"
      }
    ]
  },
  {
    title: "Dentzel",
    description:
      "Named for Dentzel Carousel Company, which built the old-fashioned carousel that remains a popular and constant fixture at the State Fair of Texas. The National Carousel Association dates many parts of the machine back to 1914. While this is the smallest room in the home, it is also the most unique. It features a private terrace with a Ferris wheel porch swing, a queen bed, and a large bathroom with spa shower.",
    images: [
      {
        url: "/../../../Rooms/Dentzel/Dentzel-1.jpg"
      },
      {
        url: "/../../../Rooms/Dentzel/Dentzel-6.jpg"
      },
      {
        url: "/../../../Rooms/Dentzel/Dentzel-2.jpg"
      },
      {
        url: "/../../../Rooms/Dentzel/Dentzel-3.jpg"
      },
      {
        url: "/../../../Rooms/Dentzel/Dentzel-4.jpg"
      },
      {
        url: "/../../../Rooms/Dentzel/Dentzel-5.jpg"
      },

      {
        url: "/../../../Rooms/Dentzel/Dentzel-7.jpg"
      },
      {
        url: "/../../../Rooms/Dentzel/Dentzel-8.jpg"
      }
    ]
  },
  {
    title: "Eastham ",
    description:
      "Named for two of the most notorious outlaws in American history. Both Bonnie and Clyde moved to west Dallas at an early age, and eventually met at a friend’s house in 1930. They started their crime spree to raise money to free their friends from Eastham Prison. This room has a plush king bed, full bathroom with a spa shower, and a claw-foot soaking tub.",
    images: [
      {
        url: "/../../../Rooms/Eastham/Eastham-8.jpg"
      },
      {
        url: "/../../../Rooms/Eastham/Eastham-9.jpg"
      },
      {
        url: "/../../../Rooms/Eastham/Eastham-1.jpg"
      },
      {
        url: "/../../../Rooms/Eastham/Eastham-2.jpg"
      },
      {
        url: "/../../../Rooms/Eastham/Eastham-3.jpg"
      },     
      {
        url: "/../../../Rooms/Eastham/Eastham-5.jpg"
      },
      {
        url: "/../../../Rooms/Eastham/Eastham-6.jpg"
      },
      {
        url: "/../../../Rooms/Eastham/Eastham-7.jpg"
      },     
    ]
  }
];
export default RoomData;