/*eslint-disable*/

import React, { Suspense, Component } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import {
  TextField,
  MenuItem,
  CardActionArea,
  CardActions
} from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { Helmet } from "react-helmet";
import { isBrowser, isTablet, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const Header = React.lazy(() => import("components/Header/Header.js"));
const HeaderLinks = React.lazy(() =>
  import("components/Header/HeaderLinks.js")
);
const Button = React.lazy(() => import("components/CustomButtons/Button.js"));
const Footer = React.lazy(() => import("components/Footer/Footer.js"));
const GridContainer = React.lazy(() => import("components/Grid/GridContainer"));
const GridItem = React.lazy(() => import("components/Grid/GridItem"));

import { getAvailability } from "services/bookStay";
import CardMedia from "components/Card/CardMedia";
import styles from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import moment from "moment";
import roomList from "./Data";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class BookStay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkInDate:
        props.location.state && props.location.state.bookingData.checkInDate,
      checkOutDate:
        props.location.state && props.location.state.bookingData.checkOutDate,
      isSearch: false,
      entireProperty: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      singleRoom: [1, 2],
      adults: props.location.state && props.location.state.bookingData.adults,
      reserve: false,
      isOpen: false,
      selectedRooms: [],
      reservedRoomList: [],
      total: 0,
      snackbarOpen: false,
      response: "",
      variant: "success",
      allProperties: [],
      availableRooms: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    this.onSearch();
  }

  onSearch = () => {
    const { checkInDate, checkOutDate, adults } = this.state;

    const checkIn = [
      checkInDate.getFullYear(),
      checkInDate.getMonth() + 1,
      checkInDate.getDate()
    ].join("/");
    const checkOut = [
      checkOutDate.getFullYear(),
      checkOutDate.getMonth() + 1,
      checkOutDate.getDate()
    ].join("/");

    const data = {
      sort: "default",
      location_id: "0,229,0,4643,0,6311110",
      start: checkIn,
      end: checkOut,
      people: adults
    };
    getAvailability(data).then(res => {
      if (res.error) {
        console.log(res.error);
      } else {
        const result = res.data.items.map(value => {
          const content = roomList.find(
            item => item.title === value.main_image.text
          ).content;
          return {
            ...value,
            content
          };
        });
        // roomList
        this.setState({
          availableRooms: result
        });
      }
    });
  };

  handleCheckInDateChange = date => {
    this.setState({
      checkInDate: new Date(date),
      checkOutDate: new Date(moment(date).add(1, "days"))
    });
  };

  handleCheckOutDateChange = date => {
    this.setState({
      checkOutDate: new Date(date)
    });
  };

  handleChange = e => {
    this.setState({
      adults: e.target.value
    });
  };

  onClickReserve = index => {
    const selectedRooms = this.state.selectedRooms;
    selectedRooms[index] = !selectedRooms[index];
    const List = selectedRooms.map((item, index) => {
      if (item) {
        return this.state.availableRooms[index];
      }
    });
    const reservedRoomList = List.filter(item => {
      if (item !== null) {
        return item;
      }
    });
    const semiTotal = reservedRoomList.reduce(
      (prev, next) => prev + next.max_price_per_night,
      0
    );
    const total = semiTotal + 350 + 72;
    this.setState({
      selectedRooms,
      reservedRoomList,
      total
    });
    this.setState({
      snackbarOpen: true,
      response: "Room has been Added Successfully",
      variant: "success"
    });
  };

  onClickRemove = index => {
    const selectedRooms = this.state.selectedRooms;
    selectedRooms[index] = !selectedRooms[index];
    this.setState({
      selectedRooms,
      snackbarOpen: true,
      response: "Room has been removed Successfully",
      variant: "error"
    });
    const List = selectedRooms.map((item, index) => {
      if (item) {
        return this.state.availableRooms[index];
      }
    });
    const filteredItems = List.slice(0, index).concat(
      List.slice(index + 1, List.length)
    );

    const reservedRoomList = filteredItems.filter(item => {
      if (item !== null) {
        return item;
      }
    });

    const semiTotal = reservedRoomList.reduce(
      (prev, next) => prev + next.max_price_per_night,
      0
    );
    const total = semiTotal + 350 + 72;

    this.setState({
      reservedRoomList,
      total
    });
  };

  handleCloseSnackbar = () => {
    this.setState({
      snackbarOpen: false
    });
  };

  goToReservation = () => {
    const {
      reservedRoomList,
      adults,
      checkInDate,
      checkOutDate,
      total
    } = this.state;
    const checkIn = [
      checkInDate.getMonth() + 1,
      checkInDate.getDate(),
      checkInDate.getFullYear()
    ].join("/");
    const checkOut = [
      checkOutDate.getMonth() + 1,
      checkOutDate.getDate(),
      checkOutDate.getFullYear()
    ].join("/");
    const uriOne = encodeURIComponent(
      "check_in_from" + checkIn + "to_check_out" + checkOut
    );
    const uriTwo = encodeURIComponent(
      "?" + reservedRoomList.length + "payment_total_" + btoa(total)
    );
    this.props.history.push(`/book-stay/${uriOne}/${uriTwo}`, {
      roomDetails: reservedRoomList,
      total,
      checkInDate: checkIn,
      checkOutDate: checkOut,
      adults
    });
  };

  render() {
    const { classes } = this.props;
    const {
      checkInDate,
      checkOutDate,
      entireProperty,
      adults,
      selectedRooms,
      reservedRoomList,
      total,
      snackbarOpen,
      variant,
      response,
      availableRooms,
    } = this.state;
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>The Gaston | Dallas | Book Stay</title>
            <meta
              name="description"
              content="Welcome to The Gaston, a unique bed & breakfast located in Old East Dallas. The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
            />
            <meta
              name="keywords"
              content="The Gaston, Dallas, House, Old East Dallas"
            />
            <meta name="og:title" property="og:title" content="The Gaston" />
            <meta
              name="og:description"
              property="og:description"
              content="The Gaston is rich in Dallas culture and each room has been uniquely designed to represent a piece of local history."
            />
            <meta
              name="og:image"
              property="og:image"
              content="https://www.thegaston.com/Rooms/the_gaston_outside-9.jpg"
            />
          </Helmet>

          <Header
            links={
              <HeaderLinks
                dropdownHoverColor="info"
                roomDetails={reservedRoomList}
                adults={adults}
                checkInDate={[
                  checkInDate.getMonth() + 1,
                  checkInDate.getDate(),
                  checkInDate.getFullYear()
                ].join("/")}
                checkOutDate={[
                  checkOutDate.getMonth() + 1,
                  checkOutDate.getDate(),
                  checkOutDate.getFullYear()
                ].join("/")}
              />
            }
            fixed
            color="white"
            changeColorOnScroll={{
              height: 100,
              color: "white"
            }}
            roomDetails={reservedRoomList}
          />
          {!isMobile && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={snackbarOpen}
              onClose={this.handleCloseSnackbar}
              autoHideDuration={2000}
            >
              <Alert severity={variant}>{response}</Alert>
            </Snackbar>
          )}
          <div
            className={classNames(
              classes.main,
              classes.mainRaised,
              isBrowser || isTablet
                ? classes.stayBlockMarginTop
                : classes.stayBlockMarginTopMobile
            )}
          >
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem sm={12} xs={12} md={10} lg={10}>
                  <h1 style={{ display: "none" }}> The Gaston - Book Stay </h1>
                  <div className={classes.bookStayMain}>
                    <div className={classes.titleDiv}>
                      <div>
                        <div className={classes.searchDiv}>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={6} md={3}>
                              <h4> Check-in </h4>
                              <div className="customDatePicker">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <DatePicker
                                    fullWidth
                                    inputVariant={"outlined"}
                                    format={"MM/DD/YYYY"}
                                    variant={"inline"}
                                    value={checkInDate}
                                    onChange={this.handleCheckInDateChange}
                                    autoOk
                                    disablePast
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3}>
                              <h4> Check-out </h4>
                              <div className="customDatePicker">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <DatePicker
                                    fullWidth
                                    inputVariant={"outlined"}
                                    format={"MM/DD/YYYY"}
                                    variant={"inline"}
                                    value={checkOutDate}
                                    onChange={this.handleCheckOutDateChange}
                                    autoOk
                                    disablePast
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3}>
                              <h4> Adults </h4>
                              <TextField
                                id="standard-select-currency"
                                select
                                fullWidth
                                variant="outlined"
                                value={adults}
                                size="small"
                                onChange={this.handleChange}
                              >
                                {entireProperty.map(option => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={6}
                              md={2}
                              className={classes.searchButtonDiv}
                            >
                              <Button
                                color="rose"
                                onClick={this.onSearch}
                                fullWidth
                                className={
                                  isMobile
                                    ? classes.marginTopButtonMobile +
                                      " " +
                                      classes.searchButton
                                    : classes.searchButton
                                }
                              >
                                <SearchIcon /> SEARCH{" "}
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </div>
                    </div>

                    {availableRooms.length === 0 && (
                      <div>
                        <h3 style={{ textAlign: "center" }}>
                          No Rooms Available
                        </h3>
                      </div>
                    )}
                    <div className={classes.roomListingDiv}>
                      <GridContainer justify="center">
                        {availableRooms &&
                          availableRooms.map(
                            (room, index) =>
                              room.max_people >= adults && (
                                <GridItem
                                  sm={12}
                                  xs={12}
                                  md={4}
                                  key={room.snippet + index}
                                >
                                  <Card className={classes.bookStayCards}>
                                    <CardActionArea>
                                      <CardMedia
                                        component="img"
                                        alt={room.snippet}
                                        height="140"
                                        image={room.main_image.url}
                                        title={room.snippet}
                                        classes={{
                                          root: classes.customCardMedia
                                        }}
                                      />
                                      <CardContent
                                        className={classes.bookStayCardContent}
                                      >
                                        <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="h2"
                                          className={classes.bookingTitle}
                                        >
                                          {room.main_image.text}
                                        </Typography>
                                        <div className={classes.priceDiv}>
                                          <p>
                                            <AccountCircleOutlinedIcon />{" "}
                                            {room.max_people}
                                          </p>
                                          <p>
                                            ${" "}
                                            {Math.round(
                                              room.max_price_per_night
                                            )}
                                          </p>
                                        </div>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          component="p"
                                        >
                                          {room.content}
                                        </Typography>
                                      </CardContent>
                                    </CardActionArea>
                                    <CardActions
                                      className={classes.reserveButtonDivMobile}
                                    >
                                      {selectedRooms[index] ? (
                                        <Button
                                          color="transparent"
                                          onClick={() =>
                                            this.onClickRemove(index)
                                          }
                                          className={classes.removeButton}
                                        >
                                          Remove
                                        </Button>
                                      ) : (
                                        <Button
                                          color="transparent"
                                          onClick={() =>
                                            this.onClickReserve(index)
                                          }
                                          className={classes.reserveButtonDiv}
                                        >
                                          Book Now
                                        </Button>
                                      )}
                                    </CardActions>
                                  </Card>
                                </GridItem>
                              )
                          )}
                      </GridContainer>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              {isMobile && reservedRoomList.length > 0 && (
                <div className={classes.reservationDetailsFloating}>
                  <div className={classes.reservationContent}>
                    <p>Total Rooms : {reservedRoomList.length} </p>
                    <p> Total: $ {Math.round(total)}.00 </p>
                  </div>
                  <div>
                    <GridContainer justify="center">
                      <GridItem sm={10} xs={8}>
                        <Button
                          color="white"
                          size="sm"
                          fullWidth
                          onClick={this.goToReservation}
                        >
                          Book Now
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              )}
            </div>
            {<Footer />}
          </div>
        </Suspense>
      </div>
    );
  }
}
BookStay.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BookStay);
