import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import Rooms from "views/Rooms/RoomDetails.js";
import Events from "views/Events/Event";
import BookStayHome from "views/BookStay/BookStayHome";
import BookStay from "views/BookStay/BookStay";
import BookNow from "views/BookStay/BookNow";
import Policies from "views/Policies/Policies";
import PhotoShoot from "views/PhotoShoot/PhotoShoot";
import Weddings from "views/Weddings/Weddings";
import WeddingInquiry from "views/Weddings/WeddingInquiry";
import PhotoShootInquiry from "views/PhotoShoot/PhotoshootInquiry";
import CityCulture from "views/CityCulture/CityCulture";
import Blog from "views/Blog/Blog";
import BlogDetails from "views/Blog/BlogDetails.js";
import Gallery from "views/Gallery/Gallery";
import UserEvents from "views/Events/UserEvents.js";
import Payment from "views/BookStay/Payment.js";
import TermsAndConditions from "views/TermsAndConditions/TermsAndConditions";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/rooms" component={Rooms} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/events" component={Events} />
      <Route path="/book-events" component={UserEvents} />
      <Route path="/book-stay" exact component={BookStayHome} />
      <Route path="/book-stay/:id" exact component={BookStay} />
      <Route path="/book-stay/:id/:id" exact component={BookNow} />
      <Route path="/policies" component={Policies} />
      <Route path="/terms-conditions" component={TermsAndConditions} />
      <Route path="/photo-shoots" component={PhotoShoot} />
      <Route path="/weddings" component={Weddings} />
      <Route path="/wedding-inquiry" component={WeddingInquiry} />
      <Route path="/photoshoot-inquiry" component={PhotoShootInquiry} />
      <Route path="/city-culture" component={CityCulture} />
      <Route path="/blog" exact component={Blog} />
      <Route path="/blog/:id" exact component={BlogDetails} />
      <Route path="/payment/:id/:id" exact component={Payment} />
      <Route path="/" component={PresentationPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
